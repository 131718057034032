<template>
    <b-col sm="12" class="p-0 m-0">
        <div class="rounded h-100">
            <b-row>
                <b-col
                    v-for="(option, index) in options"
                    :key="index"
                    :class="option.condition ? 'd-none':''"
                    cols="6"
                    md="4"
                    class="justify-content-center text-center"
                    @click="option.action"
                    v-b-popover.hover.top="option.popHover"
                    no-title>
                    <div
                        class="bg-white clr-darker-grey align-items-center mb-2 shadow p-2 add-cursor"
                        v-if="option.condition === undefined || !option.condition === true">
                        <b-icon
                            :class="`clr-${sensorType}`"
                            class="my-3"
                            :icon="option.icon"
                            scale="1.5"/>
                        <h5>
                            {{ option.text }}
                        </h5>
                    </div>
                </b-col>
            </b-row>
        </div>
    </b-col>
</template>

<script>
import swal from "sweetalert2";

export default {
    props: {
        actualSensor: Object
    },
    data() {
        return {
            options: [
                {
                    text: "Éditer les informations",
                    icon: "pencil",
                    action: this.gotToSensorEdit,
                    popHover: 'Permet de mettre à jour les champs de bases du capteurs'
                },
                {
                    text: "Éditer les accès de comptes",
                    icon: "diagram2",
                    action: () => this.$router.push('/sensors/' + this.$route.params.id + '/accounts'),
                    popHover: 'Permet de mettre à jour le compte du capteur'
                },
                {
                    text: "Éditer le statut",
                    icon: "signpost",
                    action: this.gotToSensorEditState,
                    popHover: 'Changer le statut du capteur dans son cycle (Attention peux avoir des conséquences importantes sur ça disponibilité)'
                },
                {
                    text: "Lister les Alertes",
                    icon: "exclamation-triangle",
                    action: this.goToAlerts,
                    popHover: 'Permet de lister les alertes envoyées pour ce capteur'
                },
                {
                    text: "Ajouter une note de suivi",
                    icon: "clipboard-data",
                    action: () => this.$router.push('/sensors/' + this.$route.params.id + '/following'),
                    popHover: 'Permet de laisser un commentaire pour une situation qui ne serai pas gérée'

                },
                {
                    text: "Mettre à jour la position",
                    icon: "compass",
                    action: () => this.$router.push('/sensors/' + this.$route.params.id + '/address'),
                    popHover: 'Changer les informations de son positionnement spatial (GPS, Ville, Pays ...)'
                },
                {
                    text: "Remplacer le capteur",
                    icon: "shuffle",
                    action: this.askReplaceV2,
                    popHover: 'Remplacer le capteur par un autre disponible en stock'
                },
                {
                    text: "Stocker le capteur",
                    icon: "box-arrow-in-down",
                    action: this.askStock,
                    // Add the condition for displaying the option
                    condition: this.actualStatus !== 'stock',
                    popHover: 'Remettre le capteur de force en stock'

                },
                {
                    text: "Supprimer le capteur",
                    icon: "trash",
                    action: this.askDeleteSensor,
                    popHover: 'Supprimer définitivement le capteur'
                }
            ]
        };
    },
    computed: {
        sensorType() {
            if (!this.actualSensor) return 'pollen'
            return this.actualSensor.type.toLowerCase();
        }
    },
    methods: {
        gotToSensorEdit() {
            this.$router.push({path: '/sensors/' + this.$route.params.id + '/edit', params: {type: this.type}});
        },
        goToSensorsList() {
            const isPreviouslyAccount = this.previousRoute.matched.find(match => match.path === '/accounts/:id?/information')
            if (isPreviouslyAccount) {
                this.$router.push('/accounts/' + this.previousRoute.params.id + '/information')
            } else {
                this.$router.push('/sensors');
            }
        },
        gotToSensorEditState() {
            this.$router.push('/sensors/' + this.$route.params.id + '/edit/state');
        },
        goToAlerts() {
            this.$router.push('/sensors/' + this.$route.params.id + '/alerts');
        },
        askReplaceV2() {
            this.$router.push('/sensors/' + this.$route.params.id + '/replace');
        },
        askStock() {
            const swalWithBootstrapButtons = swal.mixin({})

            swalWithBootstrapButtons.fire({
                title: 'Êtes vous sûr ?',
                text: "Le capteur sera mis en stock et détaché de son propriétaire actuel!",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonColor: '#FF5A01',
                cancelButtonColor: '#AAB4C5',
                confirmButtonText: 'Stocker!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let fields = new FormData();
                    fields.set('status', 'stock')
                    this.stockSensor([this.actualSensor.serialNumber, fields])
                    swalWithBootstrapButtons.fire(
                        'Fait!',
                        'Le capteur est parti en stock!',
                        'success'
                    )
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        'Annulation',
                        'Stockage Annulé!',
                        'error'
                    )
                }
            })
        },
        askDeleteSensor() {
            this.$swal.fire({
                title: 'Etes vous sur de vouloir supprimer ce capteur ?',
                text: "Cette action est irréversible",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonColor: '#FF5A01',
                cancelButtonColor: '#AAB4C5',
                confirmButtonText: 'Oui, je suis sur!'
            }).then((result) => {
                if (result.value) {
                    this.deleteSensor(this.$route.params.id)
                }
            })
        },
    }
};
</script>

<style>
.col-size {
    height: 200px;
}
</style>