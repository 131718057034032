<template>
    <b-row class="col-12 justify-content-center align-content-center">
        <b-col cols="12" class="text-center mt-5" v-if="loading">
            <b-spinner
                :class="`clr-${sensorType}`"
                label="Loading.."/>
        </b-col>

        <!-- SENSOR TYPE -->
        <b-col cols="12" md="8" class="mb-4" v-if="!loading">
            <b-col cols="12" sm="auto">
                <b-row class="justify-content-center">
                    <h2 class="add-cursor" @click="sensorType = 'pollen'">
                        <b-badge
                            class="clr-white"
                            :class="sensorType === 'pollen' ? 'bg-pollen' : 'bg-light-grey'">
                            Pollen
                        </b-badge>
                    </h2>
                    <div class="mx-4"></div>
                    <h2 class="add-cursor" @click="sensorType = 'pollution'">
                        <b-badge
                            class="clr-white"
                            :class="sensorType === 'pollution' ? 'bg-pollution' : 'bg-light-grey'">
                            Pollution
                        </b-badge>
                    </h2>
                </b-row>
            </b-col>
        </b-col>

        <!-- INPUT METHODS FOR SERIAL NUMBER -->
        <b-col cols="12" md="8" class="basic-card mb-4" v-if="!loading">
            <b-col cols="12" sm="auto">
                <b-row class="justify-content-around">
                    <b-button :class="sensorType" class="btn-action mr-4" v-b-modal.modal-scan>
                        <b-icon icon="upc-scan" class="mr-2 text-center"/>
                        Scanner un code
                    </b-button>
                    <b-button :class="sensorType" class="btn-action mr-4" v-b-modal.modal-upload>
                        <b-icon icon="upload" class="mr-2 text-center"/>
                        Enregistrer un code
                    </b-button>
                    <b-button :class="sensorType" class="btn-action" v-b-modal.modal-input>
                        <b-icon icon="cursor-text" class="mr-2 text-center"/>
                        Saisir un numéro de série
                    </b-button>
                    <b-col cols="12" sm="auto">
                        <p class="text-center mt-3 mb-0"
                           :class="`clr-${sensorType}`"
                           v-if="!stateSerialNumber && serialNumber && serialNumber.length > 7">
                            <b-icon icon="exclamation-circle"/>
                            Le numéro de série existe déjà
                        </p>
                    </b-col>
                </b-row>
                <b-modal id="modal-scan" title="Scanner un code" :hide-footer="true">
                    <SensorScan @fillInformation="fillInformation"/>
                </b-modal>
                <b-modal id="modal-upload" title="Enregistrer un code" :hide-footer="true">
                    <ImageBarcodeReader @decode="onDecode" @error="onError"></ImageBarcodeReader>
                </b-modal>
                <b-modal id="modal-input" title="Saisir un code" :hide-footer="true">
                    <b-form @submit.prevent="addSerialNumber" inline>
                        <b-form-input
                                class="col-12 col-md-10"
                                v-model="serialNumberInput"
                                :state="serialNumberInput === null ? null : stateSerialNumberInput && serialNumberInput.length > 7"
                                placeholder="numéro de série">
                        </b-form-input>
                        <b-button
                                :class="sensorType"
                                class="btn-action border-left-0 col-12 col-md-2"
                                :disabled="!stateSerialNumberInput"
                                type="submit">
                            OK
                        </b-button>
                        <b-form-invalid-feedback id="input-serial-number">
                            <p v-if="!stateSerialNumberInput">Le numéro de série existe
                                déjà</p>
                            <p v-if="serialNumberInput !== null && serialNumberInput.length < 8">Le numéro de série est
                                trop court</p>
                        </b-form-invalid-feedback>
                    </b-form>
                </b-modal>
            </b-col>
        </b-col>

        <!-- SAVED SERIAL NUMBERS TABLE -->
        <b-col cols="12" md="8" class="basic-card" v-if="!loading">
            <b-col cols="12" sm="auto">
                <div class="clr-dark-grey text-uppercase text-center">
                    <p>Capteurs enregistré(s)</p>
                </div>
                <div class="mt-4" v-if="sensorsToCreate.length > 0">
                    <div class="table-responsive rounded basic-table">
                        <table class="table  table-borderless mb-0">
                            <thead class="table-column-header-title">
                            <tr>
                                <th>
                                    Numéro de série
                                </th>
                                <th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(serialNumber, index) in sensorsToCreate" :key="index">
                                <td>
                                    <div>
                                        {{ serialNumber }}
                                    </div>
                                </td>
                                <td class="text-right add-cursor"
                                    :class="`clr-${sensorType}`"
                                    @click="removeSerialNumber(index)">
                                    <span class="mr-3 font-weight-bold">SUPPRIMER</span>
                                    <b-icon icon="trash-fill"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <b-row v-else class="mt-4">
                    <b-col cols="12">
                        <p class="clr-light-grey text-center">AUCUN</p>
                    </b-col>
                </b-row>
            </b-col>
        </b-col>

        <!-- NAVIGATION -->
        <b-row class="col-12 col-md-8 justify-content-between mt-5 p-0">
            <b-col cols="12" md="6" class="pl-0">
                <b-button
                    @click="$router.go(-1)"
                    :class="sensorType"
                    class="w-100 btn-cancel">
                    Annuler
                </b-button>
            </b-col>
            <b-col cols="12" md="6" class="pr-0">
                <b-button
                        :disabled="sensorsToCreate.length === 0"
                        @click="validateForm"
                        :class="sensorType"
                        class="btn-action w-100">
                    Valider
                </b-button>
            </b-col>
        </b-row>
    </b-row>
</template>

<script>
import Vuex from 'vuex'
import SensorScan from "@/components/sensor/Management/SensorScan.vue";
import {ImageBarcodeReader} from "vue-barcode-reader";
import Toast from "@/services/swal2/mixins";

export default {
    name: "SensorCreateForm",
    components: {
        SensorScan,
        ImageBarcodeReader
    },
    props: {},
    data() {
        return {
            serialNumber: null,
            serialNumberInput: null,
            sensorType: 'pollen',
            loading: false,
            sensorsToCreate: []
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            createSensors: 'createSensors',
            setAdminSensors: 'setAdminSensors'
        }),
        async onDecode(result) {
            const decoded = result.split(';')
            let decodedData = [];
            decoded.forEach((text) => {
                const code = text.split(':');
                const name = code[0];
                decodedData[name] = code[1];
            })
            await this.fillInformation(decodedData)
        },
        onError(err) {
            console.log(err)
        },
        fillInformation(data) {
            this.serialNumber = data['IMEI1'];
            this.addToQueryStringToSend();
            this.$bvModal.hide('modal-scan');
            this.$bvModal.hide('modal-upload');
        },
        addSerialNumber() {
            this.serialNumber = this.serialNumberInput;
            this.addToQueryStringToSend();
            this.$bvModal.hide('modal-input');
        },
        addToQueryStringToSend() {
            if (this.stateSerialNumber) {
                this.sensorsToCreate.push(this.serialNumber)
                this.clearData();
            }
        },
        removeSerialNumber(index) {
            this.sensorsToCreate.splice(index, 1);
        },
        async validateForm() {
            if (this.sensorsToCreate.length > 0) {
                let form = new FormData()
                for (let i = 0; i < this.sensorsToCreate.length; i++) {
                    form.append('serialNumbers[]', this.sensorsToCreate[i])
                }
                await this.createSensors({data: form, type: this.sensorType})
                if (this.getRequest.success) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Les capteurs ont été créés avec succès',
                        timer: 1500
                    }).then(() => {
                        this.$emit('resumeCreatedSensors')
                    })
                }
            } else {
                alert('Vous devez ajouter quelques capteurs à la liste de création')
            }
        },
        clearData() {
            this.serialNumber = null
            this.serialNumberInput = null
        },
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            adminSensors: 'adminSensors',
            getRequest: 'getRequest'
        }),
        stateSerialNumber() {
            if (this.serialNumber !== null) {
                return this.adminSensors.find(item => item.serialNumber === this.serialNumber) === undefined &&
                    !this.sensorsToCreate.includes(this.serialNumber);
            } else {
                return false
            }
        },
        stateSerialNumberInput() {
            if (this.serialNumberInput !== null) {
                return this.adminSensors.find(item => item.serialNumber === this.serialNumberInput) === undefined
            } else {
                return false
            }
        }
    },
    async created() {
        this.loading = true;
        await this.setAdminSensors()
        this.loading = false;
    }
}
</script>

<style lang="scss" scoped>
.basic-table {
  border: 1px solid $lighter-grey;
  background-color: $white;

  .table-column-header-title {
    background-color: $lighter-grey;
  }
}

</style>
