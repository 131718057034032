<template>
    <b-row class="justify-content-center full-view-height" :class="sensorType">
        <b-col lg="12" md="12" sm="12" cols="12" v-if="Object.keys(actualSensor).length > 0" class="p-0">
            <b-row class="justify-content-center align-content-center align-items-center">
                <b-col cols="12">
                    <p class="h5 clr-dark-grey add-cursor responsive-font">
                        <span @click="goToSensorsList">
                            <b-icon icon="chevron-left"></b-icon>
                            Retour à la liste
                        </span>
                    </p>
                </b-col>
            </b-row>

            <!-- HEADER -->
            <div class="px-3">
                <b-row class="mt-2 basic-card align-items-center position-relative">
                    <b-badge class="sensor-type-badge" :class="sensorType">
                        {{ actualSensor.type }} - {{ sensorVersionName }}
                    </b-badge>
                    <b-row class="col-12 col-md-4 sensor-account-informations" :class="sensorType">
                        <b-col cols="12" md="6">
                            <div v-if="getSensorAccess.length > 0">
                                <p @click="gotToAccountInformations(getSensorAccess[0].accountGroup.account.id)"
                                   class="add-cursor responsive-font mb-0">
                                    <b-icon-person-circle/>
                                    {{ getSensorAccess[0].accountGroup.account.name }}
                                </p>
                                <p class="mb-0">
                                    <b-icon-diagram2-fill class="responsive-font"/>
                                    {{ getSensorAccess[0].accountGroup.name }}
                                </p>
                                <p class="responsive-font">
                                <span v-if="actualSensor.sensorAddress">
                                <b-icon-geo-alt-fill/>
                                    {{ actualSensor.sensorAddress.locality }}
                                </span>
                                </p>
                                <p class="clr-darker-grey responsive-font">
                                    {{ actualSensor.sensorName }}
                                </p>
                            </div>
                            <div v-else>
                                <b-skeleton animation="wave" width="85%"/>
                                <b-skeleton animation="wave" width="55%"/>
                                <b-skeleton animation="wave" width="70%"/>
                                <b-skeleton animation="wave" width="50%" class="mt-3"/>
                                <b-skeleton animation="wave" width="60%"/>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" class="sensor-informations" :class="sensorType">
                            <div v-if="loadingStatus">
                                <b-skeleton animation="wave" width="85%"/>
                                <b-skeleton animation="wave" width="55%"/>
                                <b-skeleton animation="wave" width="70%"/>
                                <b-skeleton animation="wave" width="50%"/>
                            </div>
                            <div v-else>
                                <p class="h2 responsive-font"
                                   @click="copyText(actualSensor.serialNumber)">
                                    <b-badge>
                                        N° : {{ actualSensor.serialNumber | toUpperCase }}
                                    </b-badge>
                                </p>
                                <h2 class="h5">
                                    <b-badge>ID : {{ actualSensor.id }}</b-badge>
                                </h2>
                                <p class="h5">
                                    <b-badge>Visibilité : {{ visibility }}</b-badge>
                                </p>
                                <p class="h5">
                                    <b-badge>Statut : {{ status | capitalize }}</b-badge>
                                </p>
                                <div class="mt-4" v-if="!eventsLoading && ongoingSensorEvents.length > 0">
                                    <p
                                        class="h5"
                                        :key="`sensor_event_${index}`"
                                        v-for="(event, index) in ongoingSensorEvents">
                                        <b-badge
                                            :class="contentByEventType[event.type].class"
                                            class="font-weight-normal p-2">
                                            <b-icon
                                                :icon="contentByEventType[event.type].icon"/>
                                            {{ contentByEventType[event.type].text }} :
                                            {{ event.title }}
                                        </b-badge>
                                    </p>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="col-12 col-md-8 sensor-laq" :class="sensorType">
                        <b-col cols="12" md="3" class="add-cursor sensor-action">
                            <b-row class="bg-white p-2" v-if="loadingStatus">
                                <b-col cols="3" class="text-center">
                                    <b-skeleton type="avatar"/>
                                </b-col>
                                <b-col cols="9">
                                    <b-skeleton animation="wave" width="85%"/>
                                    <b-skeleton animation="wave" width="55%"/>
                                </b-col>
                            </b-row>
                            <b-row class="bg-white p-2" v-else>
                                <b-col cols="3" class="text-center">
                                <span class="dot medium-size"
                                      :class="isOnline(actualSensor.lastUpdate,2) ? 'bg-green' : 'bg-dark-gre'"></span>
                                </b-col>
                                <b-col cols="9">
                                    <p class="responsive-font m-0">
                                        Données Brutes
                                    </p>
                                    <p class="m-0 description">
                                        {{ actualSensor.lastUpdate | dateDayMonth() }}
                                    </p>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" md="3" class="add-cursor sensor-action">
                            <b-row class="bg-white p-2" v-if="loadingStatus">
                                <b-col cols="3" class="text-center">
                                    <b-skeleton type="avatar"/>
                                </b-col>
                                <b-col cols="9">
                                    <b-skeleton animation="wave" width="85%"/>
                                    <b-skeleton animation="wave" width="55%"/>
                                </b-col>
                            </b-row>
                            <b-row class="bg-white p-2" v-else>
                                <b-col cols="3" class="text-center">
                                <span class="dot medium-size"
                                      :class="isOnline(actualSensor.lastDataFlowUpdate,2) ? 'bg-green' : 'bg-dark-grey'"></span>
                                </b-col>
                                <b-col cols="9">
                                    <p class="responsive-font m-0">Données Métiers</p>
                                    <p class="m-0 description">
                                        {{ actualSensor.lastDataFlowUpdate | dateDayMonth }}
                                    </p>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" md="3" class="add-cursor sensor-action"
                               @click="goToSensorOffsetHistory">
                            <b-row class="bg-white p-2" v-if="loadingStatus">
                                <b-col cols="3" class="text-center">
                                    <b-skeleton type="avatar"/>
                                </b-col>
                                <b-col cols="9">
                                    <b-skeleton animation="wave" width="85%"/>
                                    <b-skeleton animation="wave" width="55%"/>
                                </b-col>
                            </b-row>
                            <b-row v-else-if="actualOffsetHistory && isOffsetRecent" class="bg-white p-2">
                                <b-col cols="3" class="text-center">
                                <span class="dot medium-size"
                                      :class="offsetColor(actualOffsetHistory)"/>
                                </b-col>
                                <b-col cols="9">
                                    <p class="responsive-font m-0">{{
                                            $t('common.title.offsetStatus') | capitalize
                                        }}</p>
                                    <p class="m-0 description" v-if="actualOffsetHistory === null">{{
                                            $t('error.noData') | capitalize
                                        }}</p>
                                    <p class="m-0 description" v-else>
                                        {{ $t(`offset.level.${offsetColor(actualOffsetHistory)}`) | capitalize }}</p>
                                </b-col>
                            </b-row>
                            <b-row v-else class="bg-white p-2">
                                <b-col cols="3" class="text-center">
                                    <span class="dot medium-size bg-dark-grey"></span>
                                </b-col>
                                <b-col cols="9">
                                    <p class="responsive-font m-0">{{
                                            $t('common.title.offsetStatus') | capitalize
                                        }}</p>
                                    <p class="m-0 description">{{
                                            $t(`error.noDataFor`, {days: offsetPeriod}) | capitalize
                                        }}</p>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" md="3" class="add-cursor sensor-action" @click="gotToSensorOperationRate">
                            <b-row class="bg-white p-2" v-if="loadingStatus">
                                <b-col cols="3" class="text-center">
                                    <b-skeleton type="avatar"/>
                                </b-col>
                                <b-col cols="9">
                                    <b-skeleton animation="wave" width="85%"/>
                                    <b-skeleton animation="wave" width="55%"/>
                                </b-col>
                            </b-row>
                            <b-row v-else-if="sensorsRate === null || isOperationRateRecent"
                                   class="bg-white p-2">
                                <b-col cols="3" class="text-center">
                                <span class="dot medium-size"
                                      :class="operationRateColor(sensorsRate)"/>
                                </b-col>
                                <b-col cols="9">
                                    <p class="responsive-font m-0">{{
                                            $t('common.entity.operationRate') | capitalize
                                        }}</p>
                                    <p class="m-0 description" v-if="sensorsRate === null">
                                        {{ $t('error.noData') | capitalize }}
                                    </p>
                                    <p class="m-0 description" v-else>
                                        {{ sensorsRate.rate + '%' }}
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row v-else class="bg-white p-2">
                                <b-col cols="3">
                                    <span class="dot medium-size bg-dark-grey"></span>
                                </b-col>
                                <b-col cols="9">
                                    <p class="responsive-font m-0">{{
                                            $t('common.entity.operationRate') | capitalize
                                        }}</p>
                                    <p class="m-0 description">
                                        {{ $t(`error.noDataFor`, {days: operationRatePeriod}) | capitalize }}</p>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-row>
            </div>

            <!-- CONTENT -->
            <SensorSideMenu :currentProperties="currentProperties"/>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex';
import Toast from "@/services/swal2/mixins"
import moment from 'moment'
import 'vue-step-progress/dist/main.css';
import SensorSideMenu from "@/components/sensor/Detail/SensorSideMenu.vue";
import operationRateCalculator from "@/services/sensor/operationRateCalculator";
import offsetCalculator from "@/services/sensor/offsetCalculator";

export default {
    name: "SensorDetail",
    components: {
        SensorSideMenu
    },
    props: {
        type: {
            type: String,
            default: 'pollen'
        },
    },
    data() {
        return {
            dynamoValues: {},
            isBusy: false,
            matoomaToken: {},
            matoomaData: {},
            isOffsetRecent: true,
            offsetPeriod: null,
            isOperationRateRecent: true,
            operationRatePeriod: null,
            isViabilityLoading: true,
            consumptionData: null,
            consumptionDailyData: null,
            simData: null,
            comments: '',
            previousRoute: null,
            visibilityOptions: [
                {value: 'public', text: 'Publique'},
                {value: 'private', text: 'Privé'},
                {value: 'hidden', text: 'Caché'},
            ],
            timelineItems: [],
            timelineKey: 0,
            dynamicComponent: 'SensorFollowUp',
            contentByEventType: {
                incident: {
                    text: 'Incident',
                    icon: 'exclamation-circle-fill',
                    class: 'clr-white bg-danger'
                }
            },
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            setIndividualSensors: 'setIndividualSensors',
            setIndividualSensorAccess: 'setIndividualSensorAccess',
            emptyIndividualSensor: 'emptyIndividualSensor',
            setHistories: 'setHistories',
            setAlerts: 'setAlerts',
            emptyHistories: 'emptyHistories',
            emptyAlerts: 'emptyAlerts',
            setSensorOffsetHistory: "setSensorOffsetHistory",
            setOperationRateData: "setOperationRateData",
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts',
        }),
        ...Vuex.mapActions('matooma', {
            setConsumption: 'setConsumption'
        }),
        ...Vuex.mapActions('sensorEvent', {
            setSensorEvents: 'setSensorEvents',
            emptySensorEvents: 'emptySensorEvents'
        }),
        goToSensorsList() {
            const isPreviouslyAccount = this.previousRoute.matched.find(match => match.path === '/accounts/:id?/information')
            if (isPreviouslyAccount) {
                this.$router.push('/accounts/' + this.previousRoute.params.id + '/information')
            } else {
                this.$router.push('/sensors');
            }
        },
        goToSensorOffsetHistory() {
            this.$router.push('/sensors/' + this.$route.params.id + '/history/offset');
        },
        gotToSensorOperationRate() {
            this.$router.push('/sensors/' + this.$route.params.id + '/history/operation-rate');
        },
        async gotToAccountInformations(accountId) {
            if (this.accounts.length === 0) {
                await this.setAccounts()
            }
            this.$router.push('/accounts/' + accountId + '/information');
        },
        isOnline(date, offset = 2) {
            if (!date) return false;
            let utcData = moment.utc(date);
            let twoHourAgo = moment.utc().subtract(offset, 'hours').toDate().toUTCString();
            return utcData.isAfter(twoHourAgo);
        },
        copyText(text) {
            navigator.clipboard.writeText(text);
            Toast.fire({
                icon: 'success',
                title: "L'ID du capteur a été copié dans le presse-papier",
            })
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        formatDate(date) {
            return [
                date.getFullYear(),
                this.padTo2Digits(date.getMonth() + 1),
                this.padTo2Digits(date.getDate()),
            ].join('-');
        },
        async consumptionTotal() {
            let date = new Date();
            date.setDate(date.getDate() - new Date().getDate() - 1);
            let params = {
                iccid: this.actualSensor.sensorParameters.simId,
                period: {start: this.formatDate(date), end: this.formatDate(new Date())}
            }
            await this.setConsumption(params);
        },
        async setConsumptionTotal() {
            this.getConsumption.then(result => {
                if (result.data) {
                    this.consumptionData = Math.round(result.data.consumption[0].offer.data * Math.pow(10, -6));
                }
            })
        },
        async setSimData() {
            await this.getSimInfo.then(result => {
                if (result.data) {
                    this.simData = result.data[0];
                }
            })
        },
        async setConsumptionDaily() {
            this.getDailyConsumption.then(result => {
                if (result.data) {
                    this.consumptionDailyData = {
                        aggregated: result.data.data_offer.aggregated_volumes.slice(0, -1),
                        last: Math.round(result.data.data_offer.aggregated_volumes.at(-2).aggregated_volume * Math.pow(10, -6) * 100) / 100
                    }
                }
            })
        },
        async makeHistory() {
            this.timelineItems = [];
            for (let i = 0; i < this.histories.length; i++) {
                this.timelineItems.push(this.histories[i]);
            }
        },
        operationRateColor(sensorsRate) {
            if (this.sensorsRate === null || !this.isOperationRateRecent) return 'bg-dark-grey'
            if (sensorsRate) return operationRateCalculator.calculateOperationRateLevel(sensorsRate.rate)
            return 'bg-dark-grey'
        },
        offsetColor(data) {
            if (this.actualOffsetHistory === null || !this.isOffsetRecent) {
                return 'bg-dark-grey'
            }
            return offsetCalculator.calculateOffsetLevel(data, this.actualSensor.sensorVersion);
        },
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            sensors: 'sensors',
            histories: 'histories',
            actualOffsetHistory: "actualOffsetHistory",
            sensorsRate: "sensorsRate",
            chosenType: 'chosenType',
            individualSensor: 'individualSensor',
            getSensorAccess: 'getSensorAccess',
            loadingStatus: 'loadingStatus'
        }),
        ...Vuex.mapGetters('sensorList', {
            getStepNameByValue: 'getStepNameByValue',
        }),
        ...Vuex.mapGetters('account', {
            accounts: 'accounts',
        }),
        ...Vuex.mapGetters('matooma', {
            getConsumption: 'getConsumption',
            getDailyConsumption: 'getDailyConsumption',
            getSimInfo: 'getSimInfo',
            isConsumptionLoading: 'getLoadingStatus'
        }),
        ...Vuex.mapGetters('sensorEvent', {
            ongoingSensorEvents: 'ongoingSensorEvents',
            eventsLoading: 'isLoading'
        }),
        currentProperties() {
            // Permet de Bind dynamiquement les props
            return {actualSensor: this.actualSensor, actualStatus: this.actualStatus, type: this.type}
        },
        actualSensor() {
            return this.individualSensor
        },
        sensorType() {
            if (!this.individualSensor || !this.individualSensor.type) return 'pollen'
            return this.individualSensor.type.toLowerCase();
        },
        actualStatus() {
            if (this.actualSensor.sensorParameters) {
                return this.actualSensor.sensorParameters.status
            } else {
                return null
            }
        },
        visibility() {
            return this.visibilityOptions.find(item => item.value === this.individualSensor.visibility).text
        },
        status() {
            let parameters = this.individualSensor.sensorParameters;
            return parameters.status + ' - ' + this.getStepNameByValue(parameters.status, parameters.statusStep)
        },
        sensorVersionName() {
            if (!this.individualSensor || !this.individualSensor.sensorVersion) return ''
            const nameByVersion = {
                pollution: {
                    v1: 'LC1',
                    v2: 'LC2',
                },
                pollen: {
                    v1: 'BN1',
                    v2: 'BN2',
                    v3: 'BN3',
                }
            }
            return nameByVersion[this.sensorType][this.individualSensor.sensorVersion];
        }
    },
    async created() {
        let status = this.chosenType;
        if (status === 'pollen' || status === 'pollution') status = 'prod';
        await this.setIndividualSensors(this.$route.params.id).then(() => {
            setTimeout(() => "console wait", 500)
        })
        await this.setIndividualSensorAccess(this.$route.params.id)
    },
    async mounted() {
        await this.setOperationRateData({period: 'last', sensors: 'sensors[]=' + this.$route.params.id})
        await this.setSensorOffsetHistory({sensorId: this.$route.params.id, data: {period: 'last'}})
        await this.setAlerts({sensor: this.$route.params.id, paginator: {limit: 1}})
        await this.setHistories(this.$route.params.id)
        await this.setSensorEvents(this.$route.params.id);

        this.isViabilityLoading = false;

        await this.makeHistory();
    },
    destroyed() {
        this.emptyHistories()
        this.emptyAlerts()
        this.emptyIndividualSensor()
        this.emptySensorEvents()
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.previousRoute = from
        })
    }
}
</script>

<style lang="scss" scoped>

.sensor-laq {
    &.pollution {
        .description {
            color: $live-pollution;
        }
    }

    &.pollen {
        .description {
            color: $orange-lifyair;
        }
    }
}

.sensor-action {
    &:hover {
        background-color: rgba(125, 142, 250, 0.1);
    }
}

.linkToLogic {
    &:hover {
        color: $dark-grey;
        background-color: rgba(125, 142, 250, 0.1);
        border-radius: 4px;
    }
}

.subtitle-sensor-information {
    font-size: 1.3rem;
}

.sensor-history-container {
    height: 15rem;
    overflow-y: scroll;
    text-align: left;
}

.icon-square {
    width: 2.5em;
    height: 2.5em;
}


.icon-square-big {
    width: 3.5em;
    height: 3.5em;
}

.icon-square-small {
    width: 1.5em;
    height: 1.5em;
}

.shadow-blur {
    box-shadow: inset 0 0 1px 1px hsla(0, 0%, 99.6%, .9), 0 20px 27px 0 rgba(0, 0, 0, .05) !important;
}

.blur {
    box-shadow: inset 0 0 2px hsla(0, 0%, 99.6%, .8196078431372549);
    -webkit-backdrop-filter: saturate(200%) blur(30px);
    backdrop-filter: saturate(200%) blur(30px);
    background-color: hsla(0, 0%, 100%, .8) !important;
}

.responsive-font {
    font-size: calc(15px + 0.390625vw)
}

.sensor-type-badge {
    position: absolute;
    top: -20px;
    left: 45%;
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
    letter-spacing: .2rem;
    font-family: 'Myriad Pro Semibold', sans-serif;

    &.pollution {
        background-color: $live-pollution;
    }

    &.pollen {
        background-color: $orange-lifyair;
    }
}

.sensor-account-informations {
    &.pollution {
        color: $live-pollution;
    }

    &.pollen {
        color: $orange-lifyair;
    }
}

.sensor-informations {
    .badge {
        color: white;
        padding: 7px;
    }

    &.pollution {
        .badge {
            background-color: $live-pollution;
        }
    }

    &.pollen {
        .badge {
            background-color: $orange-lifyair;
        }
    }
}

</style>
