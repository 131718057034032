<template>
    <b-col v-if="modeSensorTesting">
        <b-row class="mb-4">
            <b-col cols="12">
                <b-button @click.prevent="getSensorIdInformation"
                          class="col-12 col-md-8"
                          :class="sensorSetup.firmwareUpdate ? 'btn-action' : 'btn-cancel'">
                    {{
                    sensorSetup.firmwareUpdate ? 'Mise à jour correctement effectuée' : 'Vérifier la version du capteur'
                    }}
                </b-button>
                <b-form-text class="mt-2">
                    Si le capteur envoie une information de la version du capteur (intel ou lattice), cela signifie que
                    la mise à jour a été faite
                </b-form-text>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="mt-4">
                <h6 class="add-cursor" @click="showConsole = !showConsole">
                    Voir plus d'information
                    <b-icon-chevron-up v-if="showConsole"/>
                    <b-icon-chevron-down v-else/>
                </h6>
            </b-col>
        </b-row>
        <b-row class="text-left mt-4" v-if="showConsole">
            <b-col cols="12">
                <h6 class="clr-darker-grey">
                    <span class="font-weight-bold">MQTT :</span>
                    <span class="clr-dark-grey" v-if="isConnected">&nbsp;Serveur connecté</span>
                    <span class="clr-dark-grey" v-else>&nbsp;Serveur déconnecté</span>
                </h6>
            </b-col>
        </b-row>
        <b-row class="rounded mqtt-logs" v-if="showConsole">
            <b-col cols="12">
                <h6>CONSOLE :</h6>
                <p v-for="(message,i) in messages" :key="i" class="m-0">{{ message }}</p>
            </b-col>
        </b-row>
    </b-col>
    <b-row v-else>
        <b-col cols="12" class="bg-white rounded p-3">
            <h6 class="">
                <span class="font-weight-bold clr-darker-grey">
                    MQTT :
                </span>
                <span class="clr-dark-grey" v-if="isConnected">&nbsp; Serveur connecté</span>
                <span class="clr-dark-grey" v-else>&nbsp; déconnecté</span>
            </h6>
            <b-row class="my-4 justify-content-around">
                <b-col cols="5" class="shadow p-3 m-1">
                    <p>Subscribe to new Channel</p>
                    <b-form @submit.prevent="handleSubscriptionRequest" class="d-flex d-inline">
                        <b-form-input
                                v-model="desiredSubscriptionTopic"
                                :value="desiredSubscriptionTopic" class="w-75"
                                placeholder="Add Topics..."
                        />
                        <b-button type="submit" :class="`bg-${sensorType}`" class="ml-2 clr-white">
                            Subscribe
                        </b-button>
                    </b-form>
                    <b-tag
                            v-for="(topic,i) in subscribedTopics" :key="i"
                            class="bg-white m-1"
                            :class="`clr-${sensorType} border-${sensorType}`"
                            @click="handleUnsubscribe(topic)"
                            @remove="handleUnsubscribe(topic)"
                    >
                        {{ topic }}
                    </b-tag>
                </b-col>
                <b-col cols="5" class="shadow p-3 m-1">
                    <p>
                        Publish to channel
                    </p>
                    <b-form @submit.prevent="handlePublishRequest" class="d-flex d-inline">
                        <b-form-input
                                v-model="desiredPublishTopic"
                                :value="desiredPublishTopic"
                                placeholder="Add Topic..."
                        >
                        </b-form-input>
                        <b-form-select v-model="desiredPublishMessage" :options="orderList"></b-form-select>
                        <b-button class="clr-white" :class="`bg-${sensorType}`" type="submit">Publish</b-button>
                    </b-form>
                </b-col>
            </b-row>
            <b-row class="p-3">
                <b-checkbox
                    :class="`clr-${sensorType} check-box-${sensorType}`"
                    v-model="checkedHash">
                    check if you want to encrypt decrypt content automatically ({{ checkedHash }})
                </b-checkbox>
            </b-row>
            <b-row
                    class="bg-darker-grey clr-white p-3 m-1 rounded flex-column"
                    style="max-height:300px;overflow-x:hidden;"
            >
                <b-col cols="12" class="h-25 overflow-auto text-left"
                       style="max-height:300px;overflow-x:hidden;">
                    <h6>
                        CONSOLE :
                    </h6>
                    <br>
                    <span v-for="(message,i) in messages" :key="i">{{ message }}<br></span>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
// import { Auth } from 'aws-amplify';
import AWSIoTData from 'aws-iot-device-sdk'
import moment from "moment";
import crypto from 'crypto'
import Vuex from "vuex";

export default {
    name: "FullMQTT",
    props: {
        serialNumber: String,
        modeIndividual: {
            type: Boolean,
            default: false
        },
        modeSensorTesting: {
            type: Boolean,
            default: false
        },
        actualSensor: Object
    },
    data() {
        return {
            messages: [],
            payloads: [],
            loading: false,
            showConsole: false,
            sensorId: null,
            isConnected: false,
            subscribedTopics: ['SYS/#', 'DIAG/#'],
            desiredPublishTopic: 'SYS/869336031293820/envoie/',
            desiredSubscriptionTopic: '',
            desiredPublishMessage: null,
            mqttClient: {},
            orderList: [
                {value: '2', text: 'Informations Capteur'},
                {value: '15', text: 'REBOOT Capteur'},
                {value: '18', text: 'Informations Seuil'},
                {value: '56', text: 'Modifier ID Capteur'},
                {value: '57', text: 'Lire ID Capteur'},
                {value: '89', text: 'Allumer Laser'},
                {value: '125', text: 'Éteindre Laser'},
                {value: '145', text: 'Allumer Pompe'},
                {value: '159', text: 'Éteindre Pompe'},
                {value: '992', text: 'Allumer Ventilateur'},
                {value: '2122', text: 'Éteindre Ventilateur'},
                {value: null, text: '-- Sélectionner une action --'},
            ],
            checkedHash: true
        }
    },
    methods: {
        async connectToAwsIot() {
            // This connection/function is only for publishing messages;
            // Subscriptions each get their own child object with separate connections.

            // mqtt clients require a unique clientId; we generate one below
            let clientId = 'lfr-admn-app-mqtt' + (Math.floor((Math.random() * 100000) + 1));

            // // Create an MQTT client
            let newMqttClient = AWSIoTData.device({
                region: 'eu-west-1',
                host: 'a2fkrauombzbnt-ats.iot.eu-west-1.amazonaws.com',
                clientId: clientId,
                protocol: 'wss',
                maximumReconnectTimeMs: 8000,
                debug: true,
                accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
                secretKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY
            });

            newMqttClient.on('connect', () => {
                this.isConnected = true;
            });

            if (this.isConnected) {
                this.handleSubscriptionRequest()
            }

            let that = this
            newMqttClient.on('message', function (topic, payload) {
                if (that.checkedHash) {
                    // this.de
                }
                let myDate = new moment().utc().toString();
                let newMessage = `${myDate} - Topic '${topic}' - Message: \n ${payload.toString()}`;
                that.messages.push(newMessage);
                that.payloads.push(payload.toString());
            });

            this.mqttClient = newMqttClient
        },
        arrayRemove(arr, value) {
            let index = arr.indexOf(value);
            if (index !== -1) {
                arr.splice(index, 1);
            }
        },
        handleSubscriptionRequest() {
            if (this.desiredSubscriptionTopic !== '') {
                if (!this.subscribedTopics.includes(this.desiredSubscriptionTopic)) {
                    this.subscribedTopics = [...this.subscribedTopics, this.desiredSubscriptionTopic];
                }
            }

            this.mqttClient.subscribe(this.subscribedTopics)
        },
        handlePublishRequest(e) {
            e.preventDefault();
            let message = this.desiredPublishMessage
            if (this.checkedHash) {
                message = this.encryptMessage(message)
            }
            this.mqttClient.publish(this.desiredPublishTopic, message);
            if (this.modeSensorTesting) {
                setTimeout(() => {
                    this.$emit('setMqttMessages', this.payloads)
                }, 1000)
            }
        },
        handleUnsubscribe(topic) {
            this.arrayRemove(this.subscribedTopics, topic)
            this.desiredSubscriptionTopic = ''
            this.mqttClient.unsubscribe(topic)
        },
        encryptMessage(ordre) {
            return this.gen_ordre(ordre)
        },
        gen_alea() {
            let now = moment().utc();
            const hmac = crypto.createHmac('sha256', now.format("YYYY:MM:DD HH") + Math.random().toString());
            const randomIndex = Math.floor(Math.random() * 30) + 5;
            return hmac.update('').digest('hex').toUpperCase().slice(0, randomIndex);
        },
        gen_ordre(ordre) {
            const msg = this.gen_alea();
            const code = String(9000 - (msg.length - ordre) % 9000);
            let now = moment().utc();
            const hmac = crypto.createHmac('sha256', "lify@2019" + now.format("YYYY:MM:DD HH"));
            const ext = hmac.update(msg.trim()).digest('hex').toUpperCase();
            return '{"msg":"' + msg + '", "code":"' + code + '", "ext":"' + ext + '"}';
        },
        // decryptMessage(messageToDecrypt){
        // let secret = 'lify@2019' + new Date().toISOString().slice(0, 13).replace(/[-T]/g, ':'); // convert to UTC and replace '-' and 'T' with ':'
        // if (ordre){
        //     secret += ordre.toString()
        // }
        // const hmac = crypto createHmac('sha256', secret);
        // hmac.update(messageToEncrypt);
        // return hmac.digest('hex').toUpperCase()
        // },
        getSensorIdInformation(e) {
            this.$emit('startTest')
            this.desiredPublishMessage = '57';
            this.handlePublishRequest(e);
        }
    },
    computed: {
        ...Vuex.mapGetters('sensorSetup', {
            sensorSetup: 'sensorSetup'
        }),
        sensorType() {
            if (!this.actualSensor) return 'pollen'
            return this.actualSensor.type.toLowerCase();
        },
        sensorVersion() {
            if (!this.actualSensor) return 'v2'
            return this.actualSensor.sensorVersion || "v2"
        },
    },
    async created() {
        if (this.modeIndividual) {
            if (this.sensorVersion === 'v3' && this.sensorType === 'pollen') {
                this.subscribedTopics = [
                    'sensor/pollen/BN3/' + this.serialNumber + '/sys/#',
                    'sensor/pollen/BN3/' + this.serialNumber + '/diag/#'
                ]
                this.desiredPublishTopic = 'sensor/pollen/BN3/' + this.serialNumber + '/sys/envoie/';
            } else if (this.sensorType === 'pollution' && this.sensorVersion === 'v2') {
                this.subscribedTopics = [
                    'sensor/pollution/LC2/' + this.serialNumber + '/sys/#',
                    'sensor/pollution/LC2/' + this.serialNumber + '/diag/#'
                ]
                this.desiredPublishTopic = 'sensor/pollution/LC2/' + this.serialNumber + '/sys/envoie/';
            } else {
                this.subscribedTopics = ['SYS/' + this.serialNumber + '/#', 'DIAG/' + this.serialNumber + '/#']
                this.desiredPublishTopic = 'SYS/' + this.serialNumber + '/envoie/';
            }
        }
        await this.connectToAwsIot()
    },
    watch: {
        // whenever question changes, this function will run
        isConnected(newStatus, oldStatus) {
            if (newStatus !== oldStatus && newStatus) {
                this.handleSubscriptionRequest()
            }
        }
    },
    async beforeDestroy() {
        await this.mqttClient.end()
    }
}
</script>

<style lang="scss" scoped>
.check-box-pollution:checked.check-box-pollution:before {
  color: #fff;
  border-color: $live-pollution;
  background-color: $live-pollution;
}
.check-box-pollen:checked.check-box-pollen:before {
  color: #fff;
  border-color: $orange-lifyair;
  background-color: $orange-lifyair;
}

.mqtt-logs {
  text-align: left;
  color: $white;
  background-color: $darker-grey;
  padding: 20px 10px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>