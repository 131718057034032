<template>
    <b-row class="justify-content-center">
        <b-col cols="12" class="mb-3">

            <!-- HEADER -->
            <b-row class="justify-content-center">
                <!-- MORE OPTIONS DROPDOWN -->
                <b-col cols="12" class="text-left mb-4">
                    <b-form class="account-list" inline>
                        <b-dropdown
                            class="w-auto"
                            variant="primary"
                            text="Plus d'options">
                            <b-dropdown-item v-b-modal.modal-destock-sensors variant="primary"
                                             v-if="sensorStatus === 'stock'">
                                <b-icon icon="check2-circle"></b-icon>
                                Déstocker les capteurs sélectionnés
                            </b-dropdown-item>
                            <b-dropdown-item @click="$router.push('/sensors/new')" variant="primary"
                                             v-if="sensorStatus !== 'stock'">
                                <b-icon icon="plus-circle"></b-icon>
                                Ajouter un Capteur
                            </b-dropdown-item>
                        </b-dropdown>
                        <b-form-input list="account-list"
                                      class="bg-orange clr-white ml-md-3 w-auto mt-2 mt-md-0"
                                      placeholder="Filtrer par compte"
                                      @change="changeAccount"
                                      v-model="chosenAccount"/>
                        <datalist id="account-list">
                            <option v-for="(account, index) in accounts" :key="index" :value="account.name">
                                {{ account.name }}
                            </option>
                        </datalist>
                    </b-form>
                </b-col>

                <!-- DESTOCK SENSORS MODAL -->
                <b-modal size="lg"
                         id="modal-destock-sensors"
                         title="Lancement des tests capteurs"
                         class="clr-orange"
                         :hide-footer="true">
                    <div class="d-block" v-if="checkedSensors.length > 0">
                        <div>
                            <p class="h4">
                                <b-badge v-for="item in checkedSensors"
                                         :key="item.id"
                                         class="clr-orange bg-white border-orange mr-3">
                                    {{ item.serialNumber }}
                                </b-badge>
                            </p>
                        </div>
                        <p class="clr-dark-grey mt-2">Les capteurs seront mis en phase de test.</p>
                        <div class="text-center">
                            <b-button @click="destockSensors" variant="primary" class="mt-4">Valider</b-button>
                        </div>
                    </div>
                    <div v-else>Aucune référence sélectionnée</div>
                </b-modal>

                <!-- FILTERS -->
                <b-col cols="12" md="9">
                    <b-row>
                        <b-form class="col-12" inline>
                            <b-form-group label="Type de capteur" label-class="clr-dark-grey">
                                <b-form-radio-group
                                    @change="refreshList(true)"
                                    v-model="sensorType"
                                    name="sensor-type"
                                    class="mr-3"
                                    buttons
                                    button-variant="outline-primary"
                                    :disabled="isBusy"
                                >
                                    <b-form-radio v-for="(option, index) in sensorTypeOptions"
                                                  :key="'sensor_type_' + index"
                                                  :disabled="option.disabled"
                                                  :value="option.value">{{ option.text }}
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <b-form-group label="Status du capteur" label-class="clr-dark-grey">
                                <b-form-radio-group
                                    @change="refreshList(true)"
                                    v-model="sensorStatus"
                                    name="sensor-status"
                                    buttons
                                    class="mr-3"
                                    button-variant="outline-primary"
                                    :disabled="isBusy"
                                >
                                    <b-form-radio v-for="(option, index) in sensorStatusOptions"
                                                  :key="'sensor_status_' + index"
                                                  :disabled="option.disabled"
                                                  :value="option.value">{{ option.text }}
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <b-form-group v-if="(statusStepOptions && sensorStatus) && statusStepOptions[sensorStatus].length > 0"
                                          label="État du capteur"
                                          label-class="clr-dark-grey">
                                <b-form-radio-group
                                    @change="refreshList(false)"
                                    v-model="sensorStatusStep"
                                    name="sensor-sub-status"
                                    buttons
                                    class="mr-3"
                                    button-variant="outline-primary"
                                    :disabled="isBusy"
                                >
                                    <b-form-radio v-for="(option, index) in statusStepOptions[sensorStatus]"
                                                  :key="'sensor_status_step' + index"
                                                  :disabled="option.disabled"
                                                  :value="option.value">{{ option.text }}
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <b-form-group label="Filtres" label-class="clr-dark-grey">
                                <b-button
                                    @click="refreshList(false,true)"
                                    variant="outline-primary">
                                    Afficher tout
                                </b-button>
                            </b-form-group>
                        </b-form>
                    </b-row>
                </b-col>

                <!-- SEARCH BAR -->
                <b-col cols="12" md="3" class="align-items-end align-self-end">
                    <b-row class="justify-content-center">
                        <b-input-group v-on:submit.prevent="querySensor" v-on:keyup.enter="querySensor">
                            <b-form-input v-model="search"
                                          placeholder="Rechercher (min. 3 char)">
                            </b-form-input>
                            <b-input-group-append>
                                <b-button type="submit" variant="primary" @click="querySensor">Go</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>

        <!-- POLLEN SENSORS TABLE BY TYPE -->
        <b-col cols="12" v-if="sensors.length > 0">
            <SensorPollenTable
                v-if="!sensorStatus"
                :is-busy="isBusy"
                :sensors="sensors"
                :loading-status="loadingStatus"
                :hoveredSensor="hoverSensor"
                @set-sorting="setSort"
                @hovered-sensor="setHoveredSensor"
            />
            <SensorProductionTable
                v-else-if="sensorStatus === 'prod'"
                :sensors="sensors"
                :is-busy="isBusy"
                :loading-status="loadingStatus"
                :hoveredSensor="hoverSensor"
                @set-sorting="setSort"
                @hovered-sensor="setHoveredSensor"
            ></SensorProductionTable>
            <SensorTestTable
                v-else-if="sensorStatus === 'test'"
                :sensors="sensors"
                :is-busy="isBusy"
                :statusStep="sensorStatusStep"
                :loading-status="loadingStatus"
                :hoveredSensor="hoverSensor"
                @setSorting="setSort"
                @hoveredSensor="setHoveredSensor"
            ></SensorTestTable>
            <StockTable
                v-else-if="sensorStatus === 'stock'"
                :sensors="sensors"
                :is-busy="isBusy"
                :loading-status="loadingStatus"
                :hoveredSensor="hoverSensor"
                @set-sorting="setSort"
                @hovered-sensor="setHoveredSensor"
                @checked-sensors="setCheckedSensors"
            ></StockTable>
            <SensorDeployTable
                v-else-if="sensorStatus === 'deploy'"
                :sensors="sensors"
                :is-busy="isBusy"
                :status-step="sensorStatusStep"
                :loading-status="loadingStatus"
                :hoveredSensor="hoverSensor"
                @set-sorting="setSort"
                @hovered-sensor="setHoveredSensor"
                @checked-sensors="setCheckedSensors"
            ></SensorDeployTable>
            <SensorOutTable
                v-else-if="sensorStatus === 'out'"
                :sensors="sensors"
                :is-busy="isBusy"
                :status-step="sensorStatusStep"
                :loading-status="loadingStatus"
                :hoveredSensor="hoverSensor"
                @set-sorting="setSort"
                @hovered-sensor="setHoveredSensor"
                @checked-sensors="setCheckedSensors"/>
        </b-col>

        <div class="col-12 p-0 text-center my-5" v-else-if="!isBusy && sensors.length === 0">
            NO SENSORS FOUND
        </div>
        <b-col cols="12" class="p-0" v-else>
            <b-skeleton-table
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
                class="table-height"
            ></b-skeleton-table>
        </b-col>

        <!-- PAGINATION -->
        <b-row class="w-100 justify-content-around align-items-center mt-2 mt-md-0"
               v-if="!isBusy && sensors.length > 0">
            <b-col cols="12" md="4" class="font-weight-bold">
                Total Capteur : {{ paginator.totalCount }} <br>
                Capteur par Page : {{ paginator.numItemsPerPage }}
            </b-col>
            <b-col cols="12" md="4" class="mt-3">
                <b-pagination
                    v-model="paginator.current"
                    pills
                    :total-rows="paginator.totalCount"
                    :per-page="paginator.numItemsPerPage"
                    align="center"
                >
                </b-pagination>
            </b-col>
            <b-col cols="12" md="4" class="my-3">
                <b-form-select
                    v-model="paginator.numItemsPerPage"
                    :options="optionsLimits">
                </b-form-select>
            </b-col>
        </b-row>
    </b-row>
</template>

<script>
import Vuex from 'vuex';
import StockTable from "@/components/sensor/Stock/SensorStockTable";
import secureLS from "@/plugins/secureLS";
import Toast from "@/services/swal2/mixins";
import SensorTestTable from "@/components/sensor/Testing/SensorTestTable.vue";
import SensorProductionTable from "@/components/sensor/Production/SensorProductionTable.vue";
import SensorDeployTable from "@/components/sensor/Deploy/SensorDeployTable.vue";
import SensorOutTable from "@/components/sensor/Out/SensorOutTable.vue";
import SensorPollenTable from "@/components/sensor/SensorPollenTable.vue";

export default {
    name: "Sensors",
    components: {
        SensorPollenTable,
        SensorOutTable,
        SensorDeployTable,
        SensorProductionTable,
        SensorTestTable,
        StockTable
    },
    data() {
        return {
            isBusy: false,
            hoverSensor: null,
            search: '',
            sort: 'dr_account.name',
            direction: 'asc',
            optionsLimits: [
                {value: 10, text: '10'},
                {value: 25, text: '25'},
                {value: 50, text: '50'}
            ],
            sensorTypeOptions: [
                {text: 'Pollen', value: 'pollen', disabled: false},
                {text: 'Pollution', value: 'pollution', disabled: false},
            ],
            sensorStatusOptions: [
                {text: 'Production', value: 'prod', disabled: false},
                {text: 'Déploiement', value: 'deploy', disabled: false},
                {text: 'Test', value: 'test', disabled: false},
                {text: 'Stock', value: 'stock', disabled: false},
                {text: 'Out', value: 'out', disabled: false},
            ],
            statusStepOptions: {
                prod: [
                    {text: 'En ligne', value: 1, disabled: false},
                    {text: 'Vigilance', value: 2, disabled: false},
                    {text: 'Quarantaine', value: 3, disabled: false},
                ],
                deploy: [
                    {text: 'Transit', value: 1, disabled: false},
                    {text: 'En attente d\'installation', value: 2, disabled: false},
                ],
                test: [
                    {text: 'En cours de test', value: 1, disabled: false},
                    {text: 'Testé', value: 2, disabled: false},
                ],
                stock: [
                    {text: 'Stock', value: 1, disabled: false},
                    {text: 'Retour', value: 2, disabled: false},
                    {text: 'Maintenance', value: 3, disabled: false},
                ],
                out: [
                    {text: 'Abandon client', value: 1, disabled: false},
                    {text: 'Hors cycle', value: 2, disabled: false},
                ],
            },
            sensorType: 'pollen',
            sensorStatus: 'prod',
            sensorStatusStep: 1,
            checkedSensors: [],
            chosenAccount: null
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            sensors: 'sensors',
            getRequest: 'getRequest',
            paginator: 'paginator',
            loadingStatus: 'loadingStatus',
            chosenType: 'chosenType',
            chosenStatus: 'chosenStatus',
            chosenStatusStep: 'chosenStatusStep'
        }),
        ...Vuex.mapGetters('account', {
            accounts: 'accounts',
        }),
        hoveredSensor() {
            return this.hoverSensor
        },
        currentUser() {
            return secureLS.get('currentUser');
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            setSensors: 'setSensors',
            setChosenType: 'setChosenType',
            setChosenStatus: 'setChosenStatus',
            setChosenStatusStep: 'setChosenStatusStep',
            destockSensor: 'destockSensor'
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        setHoveredSensor(id) {
            this.hoverSensor = id;
        },
        async querySensor(resetPagination = false) {
            if (!this.isBusy) {
                this.isBusy = true;
                let params = {
                    status: this.sensorStatus,
                    statusStep: this.sensorStatusStep,
                    type: this.sensorType,
                    paginated: true
                }
                if (!this.paginator || resetPagination) {
                    params.page = 1;
                    params.limit = 50;
                } else {
                    if (this.paginator.current > 1 && this.paginator.current) {
                        params.page = this.paginator.current
                    } else {
                        params.page = 1;
                    }
                    params.limit = this.paginator.numItemsPerPage || 50
                }
                this.search.length >= 3 ? params.search = this.search : '';
                params.direction = this.direction;
                params.sort = this.sort;
                if (this.chosenAccount) {
                    let account = this.accounts.find(account => account.name === this.chosenAccount);
                    params.account = account.id;
                }

                await this.setSensors(params)
                this.isBusy = false;
            }
        },
        setSort(data) {
            this.sort = data
            this.direction === 'desc' ? this.direction = 'asc' : this.direction = 'desc';
            this.querySensor()
        },
        async refreshList(refreshStep = false, clear = false) {
            if (clear) {
                this.sensorStatus = null;
                this.sensorStatusStep = null;
                this.chosenAccount = null;
            } else if (refreshStep) this.sensorStatusStep = 1;
            await this.setChosenStatus(this.sensorStatus)
            await this.querySensor(refreshStep)
        },
        async changeAccount() {
            await this.querySensor()
        },
        setCheckedSensors(sensors) {
            this.checkedSensors = sensors;
        },
        async destockSensors() {
            if (this.checkedSensors.length > 0) {
                let form = new FormData()
                for (let i = 0; i < this.checkedSensors.length; i++) {
                    form.append('serialNumbers[]', this.checkedSensors[i].serialNumber)
                }
                await this.destockSensor({data: form})
                this.$bvModal.hide('modal-destock-sensors')
                if (this.getRequest.success) {
                    await Toast.fire({
                        icon: 'success',
                        title: 'Les capteurs ont été mis en phase de test',
                        timer: 1500
                    })
                    await this.setChosenType('pollen')
                    await this.setChosenStatus('test')
                    await this.setChosenStatusStep(1)
                    this.sensorType = this.chosenType
                    this.sensorStatus = this.chosenStatus
                    this.sensorStatusStep = this.chosenStatusStep
                    await this.querySensor()
                }
            }
        }
    },
    async created() {
        this.sensorType = this.chosenType || 'pollen'
        this.sensorStatus = this.chosenStatus || 'prod'
        this.sensorStatusStep = this.chosenStatusStep || 1
        await this.querySensor()
        await this.setAccounts()
    },
    watch: {
        'paginator.current'(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.querySensor()
            }
        },
        'paginator.numItemsPerPage'(newValue, oldValue) {
            if (oldValue !== undefined && newValue !== oldValue) {
                this.querySensor()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.account-list {
    .form-control {
        border: 1px solid $orange-lifyair;
    }

    input[type="text"]::-webkit-input-placeholder {
        color: $white;
    }
}
</style>
