<template>
    <div>
        <!-- TEST GSM -->
        <b-form class="mb-5" @submit.prevent="confirmIntraStep('gsm')" inline>
            <p class="col-12 p-0 font-weight-bold">Numéro de carte SIM :</p>
            <b-form-input
                    id="simId-input"
                    class="col-10 col-md-7 text-center border-right-0"
                    v-model="sensorConfig.simId">
            </b-form-input>
            <b-button
                    :disabled="!sensorConfig.simId ? true : sensorConfig.simId.length < 19"
                    class="btn-action no-border-left shadow-none col-2"
                    type="submit">
                OK
            </b-button>
            <b-col cols="2" md="2" class="ml-auto">
                <p class="h2 clr-orange m-0">
                    <b-icon-check-circle v-if="sensorSetup.gsm"/>
                </p>
            </b-col>
            <b-form-text id="simId-input">
                Veuillez saisir le numéro ICCID qui se trouve sur la carte sim.
            </b-form-text>
            <b-form-invalid-feedback id="simId-input" class="col-12">
                <p v-if="sensorConfig.simId && sensorConfig.simId.length < 19" class="text-left">
                    L'identifiant de SIM est trop court
                </p>
            </b-form-invalid-feedback>
        </b-form>

        <!-- TEST LASER -->
        <b-form class="mb-5" @submit.prevent="confirmIntraStep('laser')" inline>
            <p class="col-12 p-0 font-weight-bold">Puissance du laser :</p>
            <b-form-input
                    id="laser-input"
                    class="col-10 col-md-7 text-center border-right-0"
                    v-model="sensorConfig.laser">
            </b-form-input>
            <b-button
                    :disabled="!sensorConfig.laser"
                    class="btn-action no-border-left shadow-none col-2"
                    type="submit">
                OK
            </b-button>
            <b-col cols="2" md="2" class="ml-auto">
                <p class="h2 clr-orange m-0">
                    <b-icon-check-circle v-if="sensorSetup.laser"/>
                </p>
            </b-col>
            <b-form-text id="laser-input">
                Veuillez saisir la puissance du laser. Plage autorisée entre 24 et 39 mW pour un capteur de Pollen et ente 40 et 60mW pour un capteur de Pollution.
            </b-form-text>
        </b-form>

        <!-- TEST CROSS COMPARISON -->
        <b-form @submit.prevent="confirmIntraStep('crossComparison')" inline>
            <b-button class="py-2 px-3 col-10 col-md-7"
                      type="submit"
                      :class="sensorConfig.crossComparison || sensorSetup.crossComparison ? 'btn-action' : 'btn-cancel'">
                {{
                    sensorSetup.crossComparison ? 'Intercomparaison réussie' : 'Valider l\'intercomparaison'
                }}
            </b-button>
            <b-col cols="2" md="2" class="ml-auto">
                <p class="h2 clr-orange m-0">
                    <b-icon-check-circle v-if="sensorSetup.crossComparison"/>
                </p>
            </b-col>
        </b-form>
    </div>
</template>

<script>
import Vuex from "vuex";

export default {
    name: "SensorConfigTest",
    data() {
        return {
            sensorConfig: {
                simId: null,
                laser: null,
                crossComparison: null,
            }
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            editSensorSimple: 'editSensorSimple'
        }),
        ...Vuex.mapActions('sensorSetup', {
            editSensorSetup: 'editSensorSetup'
        }),
        async confirmIntraStep(field) {
            const form = new FormData();
            form.append(field, 'true')
            await this.editSensorInformation(field);
            await this.editSensorSetup({id: this.sensorSetup.id, params: form});
        },
        editSensorInformation(field) {
            const sensorForm = new FormData();
            switch (field) {
                case 'gsm':
                    sensorForm.append('simId', this.sensorConfig.simId)
                    this.editSensorSimple([this.individualSensor.serialNumber, sensorForm]);
                    break;
                case 'laser':
                    sensorForm.append('laserPower', this.sensorConfig.laser)
                    this.editSensorSimple([this.individualSensor.serialNumber, sensorForm]);
                    break;
                default:
                    break;
            }
        },
    },
    computed: {
        ...Vuex.mapGetters('sensorSetup', {
            sensorSetup: 'sensorSetup'
        }),
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor'
        }),
    },
    created() {
        this.sensorConfig.simId = this.individualSensor.sensorParameters.simId;
        this.sensorConfig.laser = this.individualSensor.sensorParameters.laserPower;
    }
}
</script>

<style scoped>

</style>