export default {
    checkByStep
}

function checkByStep(step, data) {
    switch (step) {
        case 0:
            return checkGsm(data);
        case 1:
            return checkId(data);
        case 2:
            return checkLaser(data);
        case 3:
            return checkCounting(data);
        case 4:
            return checkG1AndG2(data);
        case 5:
            return checkG10AndG19(data);
        default:
            return null;
    }
}

function checkGsm(data) {
    let box = {};
    let message = null;

    for (let entry of data) {
        const gsmQ = parseInt(entry.GSM);
        box = {
            A: gsmQ >= -109 && gsmQ <= -95,
            B: gsmQ >= -93 && gsmQ <= -85,
            C: gsmQ >= -83 && gsmQ <= -75,
            D: gsmQ >= -73 && gsmQ <= -40,
            E: gsmQ >= -998 && gsmQ <= -109,
            F: gsmQ === -999
        };

        if (box.E) {
            message = `GSM value: ${gsmQ} (défaillant)`;
            break;
        }

        if (box.F) {
            message = `GSM value: ${gsmQ} (pas de réseaux)`;
            break;
        }
    }

    return {isValid: !box.E && !box.F, message};
}

// function checkPtu(data) {
//     let isValid = true;
//     let message = null;
//
//     for (let entry of data) {
//         const temp = parseFloat(entry.T);
//         const humidity = parseFloat(entry.U);
//         const pressure = parseFloat(entry.P);
//
//         if (temp < -25 || temp > 60 || temp === 0) {
//             message = `Pressure: ${pressure} | Temperature: ${temp} | Humidity: ${humidity}`;
//             isValid = false;
//             break;
//         }
//     }
//
//     return { isValid, message };
// }

function checkId(data) {
    let box = {A: true, B: true, C: true};
    let message = null;

    for (let entry of data) {
        const idValue = entry.ID;

        if (idValue === "0") {
            message = `ID value: ${idValue}`;
            box.A = false;
            break;
        }

        if (idValue.length !== 15) {
            message = `ID value: ${idValue}`;
            box.B = false;
            break;
        }

        if (idValue === "failure") {
            message = `ID value: ${idValue}`;
            box.C = false;
            break;
        }
    }

    return {isValid: Object.values(box).every(value => value), message};
}

function checkLaser(data) {
    let box = {B: false, C: false, D: false, E: false};
    let message = null;

    for (let entry of data) {
        const trame = entry.trame;
        let [v1, v2, v3, v4] = trame.length < 120
            ? [trame[1], trame[21], trame[41], trame[61]]
            : [trame[1], trame[32], trame[63], trame[94]];

        box = {
            B: v1 >= 300,
            C: v2 >= 300,
            D: v3 >= 300,
            E: v4 >= 300
        };

        if (!Object.values(box).every(value => !value)) {
            message = "Erreur d'offset sur : ";
            if (box.B) message += "Voie 1, ";
            if (box.C) message += "Voie 2, ";
            if (box.D) message += "Voie 3, ";
            if (box.E) message += "Voie 4, ";
        }
    }

    return {isValid: Object.values(box).every(value => value), message};
}

function checkCounting(data) {
    const results = {A: true, B: true, C: true, D: true};
    let message = null;

    const rangesConfig = (length) =>
        length < 120
            ? [
                {start: 2, end: 21, key: "A"},
                {start: 22, end: 41, key: "B"},
                {start: 42, end: 61, key: "C"},
                {start: 62, end: 81, key: "D"}
            ]
            : [
                {start: 2, end: 32, key: "A"},
                {start: 33, end: 63, key: "B"},
                {start: 64, end: 94, key: "C"},
                {start: 95, end: 125, key: "D"}
            ];

    for (const entry of data) {
        const trame = entry.trame;
        const ranges = rangesConfig(trame.length);

        for (const {start, end, key} of ranges) {
            results[key] = false;
            for (let i = start; i <= end; i++) {
                if (trame[i] !== 0) {
                    results[key] = true;
                    break;
                }
            }
            if (!results[key]) break;
        }
    }

    const isValid = Object.values(results).every(Boolean);

    if (!isValid) {
        const errors = [];
        if (!results.A) errors.push("Voie 1");
        if (!results.B) errors.push("Voie 2");
        if (!results.C) errors.push("Voie 3");
        if (!results.D) errors.push("Voie 4");
        message = `Erreur de comptage sur : ${errors.join(", ")}`;
    }

    return {isValid, message};
}

function checkG1AndG2(data) {
    let box = {A: false, B: false, C: false, D: false};
    let message = null;

    for (let entry of data) {
        const trame = entry.trame;
        let values = trame.length < 120
            ? [
                [trame[2], trame[3]],
                [trame[22], trame[23]],
                [trame[42], trame[43]],
                [trame[62], trame[63]]
            ]
            : [
                [trame[2], trame[3]],
                [trame[33], trame[34]],
                [trame[64], trame[65]],
                [trame[95], trame[96]]
            ];

        box = {
            A: values[0][0] > 30000 || values[0][1] > 30000,
            B: values[1][0] > 30000 || values[1][1] > 30000,
            C: values[2][0] > 30000 || values[2][1] > 30000,
            D: values[3][0] > 30000 || values[3][1] > 30000
        };
    }

    if (!Object.values(box).every(value => !value)) {
        message = "Erreur sur les deux premières gammes de tailles 1_2 sur : ";
        if (box.A) message += "Voie 1, ";
        if (box.B) message += "Voie 2, ";
        if (box.C) message += "Voie 3, ";
        if (box.D) message += "Voie 4, ";
    }

    return {isValid: Object.values(box).every(value => !value), message};
}

function checkG10AndG19(data) {
    let box = {A: false, B: false, C: false, D: false};
    let message = null;

    for (let entry of data) {
        const trame = entry.trame;
        let ranges = trame.length < 120
            ? [
                trame.slice(11, 21),
                trame.slice(31, 41),
                trame.slice(51, 61),
                trame.slice(71, 81)
            ]
            : [
                trame.slice(22, 32),
                trame.slice(53, 63),
                trame.slice(84, 94),
                trame.slice(115, 125)
            ];

        box = {
            A: ranges[0].some(value => value > 100),
            B: ranges[1].some(value => value > 100),
            C: ranges[2].some(value => value > 100),
            D: ranges[3].some(value => value > 100)
        };
    }

    if (!Object.values(box).every(value => !value)) {
        message = "Erreur sur les gammes de tailles 10_19 sur : ";
        if (box.A) message += "Voie 1, ";
        if (box.B) message += "Voie 2, ";
        if (box.C) message += "Voie 3, ";
        if (box.D) message += "Voie 4, ";
    }

    return {isValid: Object.values(box).every(value => !value), message};
}
