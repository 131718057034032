<template>
    <b-col cols="12" class="mb-3">
        <b-row class="justify-content-start align-items-start" align-v="stretch">
            <b-col cols="12" class="my-2">
                <b-row class="no-gutters p-0">
                    <b-col @click="selectTab('warning')"
                           :class="selectedTab === 'warning' ? 'active' : ''"
                           class="dashboard-tabs">
                        <p>À surveiller</p>
                    </b-col>
                    <b-col @click="selectTab('deploy')"
                           :class="selectedTab === 'deploy' ? 'active' : ''"
                           class="dashboard-tabs">
                        <p>Déploiement</p>
                    </b-col>
                    <b-col @click="selectTab('test')"
                           :class="selectedTab === 'test' ? 'active' : ''"
                           class="dashboard-tabs">
                        <p>Test</p>
                    </b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                </b-row>
            </b-col>
            <ul
                id="right-click-menu"
                tabindex="-1"
                ref="right"
                v-if="viewMenu"
                :style="{top: top, left: left}"
                @blur="closeMenu()">
                <li @click="goToProfile(rightClickedSensor.serialNumber)">
                    Informations du capteurs
                </li>
                <li @click="goToAccount(rightClickedSensor.owningAccount.id)">
                    Client associé
                </li>
            </ul>

            <!-- WARNING SENSORS -->
            <b-col
                v-if="selectedTab === 'warning'"
                cols="12"
                md="6"
                class="my-2">
                <b-row class="basic-card no-gutters p-0" align-v="stretch">
                    <b-col cols="12">
                        <div class="table-title">
                            <p>Incidents à traiter ({{ Object.keys(filteredWarningSensors).length }})</p>
                        </div>
                        <div v-if="isSensorMonitoringLoading"
                             class="table-responsive p-3"
                        >
                            <b-skeleton-table
                                :rows="5"
                                :columns="4"
                                :table-props="{ bordered: false, striped: true }"

                            ></b-skeleton-table>
                        </div>
                        <div class="table-responsive p-1"
                             v-else-if="Object.keys(filteredWarningSensors).length > 0">
                            <b-table-simple
                                responsive
                                hover
                                sticky-header
                                class="table-borderless"
                            >
                                <b-thead class="clr-light-grey">
                                    <b-tr class="table-column-header-title">
                                        <b-th>Capteurs</b-th>
                                        <b-th>Ville</b-th>
                                        <b-th>Client</b-th>
                                        <b-th>Problème</b-th>
                                        <b-th>Action</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr
                                        class="clr-darker-grey"
                                        v-for="(sensor, index) in filteredWarningSensors"
                                        :key="`warning_sensors_${index}`"
                                        @contextmenu="handler($event,sensor)"
                                    >
                                        <b-td
                                            @click="goToProfile(index)"
                                            class="add-cursor text-center"
                                        >
                                            <div class="d-flex px-1 py-1 justify-content-start add-hover">
                                                <h6 class="mb-0 clr-orange">{{ index }}</h6>
                                            </div>
                                        </b-td>
                                        <b-td class="text-center">
                                            <div class="d-flex px-1 py-1 justify-content-start">
                                                <h6 class="mb-0" v-if="sensor.address">
                                                    {{ sensor.address.locality }}
                                                </h6>
                                                <h6 class="mb-0" v-else>- - -</h6>
                                            </div>
                                        </b-td>
                                        <b-td @click="goToAccount(sensor.account.id)"
                                              class="add-cursor text-center"
                                        >
                                            <div class="d-flex px-1 py-1 justify-content-start add-hover"
                                                 v-if="'account' in sensor">
                                                <h6 class="mb-0 clr-darker-grey">{{ sensor.account.name }}</h6>
                                            </div>
                                        </b-td>
                                        <b-td>
                                            <p v-for="(error,j) in sensor.errors" :key="j">
                                                {{ j }} ({{
                                                    error.count
                                                }}){{
                                                    j === Object.keys(sensor.errors)[Object.keys(sensor.errors).length - 1] ? '' : ','
                                                }}
                                            </p>

                                        </b-td>
                                        <b-td>
                                            <b-button
                                                variant="outline-primary"
                                                @click.prevent="openContactModalMonitoring(index,sensor)"
                                            >
                                                Contacter
                                            </b-button>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                        <p class="clr-light-grey text-center" v-else>AUCUN</p>
                    </b-col>
                </b-row>
            </b-col>

            <!-- DISCONNECTED SENSORS -->
            <b-col
                v-if="selectedTab === 'warning'"
                cols="12"
                md="6"
                class="my-2">
                <b-row class="basic-card h-100 no-gutters p-0" align-v="stretch">
                    <b-col cols="12">
                        <div class="table-title">
                            <p>déconnectés ({{ disconnectedSensors.length }})</p>
                        </div>
                        <div v-if="getLoadingStatus"
                             class="table-responsive p-3"
                        >
                            <b-skeleton-table
                                :rows="5"
                                :columns="4"
                                :table-props="{ bordered: false, striped: true }"
                            ></b-skeleton-table>
                        </div>
                        <div class="table-responsive p-1" v-else-if="disconnectedSensors.length > 0">
                            <b-table-simple responsive
                                            hover
                                            sticky-header
                                            class="table-borderless"
                            >
                                <b-thead class="clr-light-grey">
                                    <b-tr class="table-column-header-title">
                                        <b-th>Capteurs</b-th>
                                        <b-th>Ville</b-th>
                                        <b-th>Client</b-th>
                                        <b-th>Depuis le</b-th>
                                        <b-th></b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr
                                        v-for="(sensor, index) in disconnectedSensors"
                                        :key="`disconnectedSensors_sensors_${index}`"
                                        class="clr-darker-grey add-cursor"
                                        @contextmenu="handler($event,sensor)"
                                    >
                                        <b-td class="add-cursor add-hover"
                                              @click="goToProfile(sensor.serialNumber)"
                                        >
                                            <div class="d-flex px-1 py-1 justify-content-start">
                                                <h6 class="mb-0 clr-orange">{{ sensor.serialNumber }}</h6>
                                            </div>
                                        </b-td>
                                        <b-td>
                                            <div class="d-flex px-1 py-1 justify-content-start">
                                                <h6 class="mb-0" v-if="sensor.sensorAddress">
                                                    {{ sensor.sensorAddress.locality }}
                                                </h6>
                                                <h6 class="mb-0" v-else>- - -</h6>
                                            </div>
                                        </b-td>
                                        <b-td @click="goToAccount(sensor.owningAccount.id)"
                                              class="add-cursor text-center"
                                        >
                                            <div class="d-flex px-1 py-1 justify-content-start add-hover"
                                                 v-if="sensor.owningAccount">
                                                <h6 class="mb-0 clr-darker-grey">{{ sensor.owningAccount.name }}</h6>
                                            </div>
                                            <div class="d-flex px-1 py-1 justify-content-start add-hover" v-else>
                                                <h6 class="mb-0 clr-darker-grey">Aucun Compte</h6>
                                            </div>
                                        </b-td>
                                        <b-td>
                                          <span class="text-secondary font-weight-bold clr-red">
                                            {{ sensor.lastUpdate | dateDayMonth }}
                                          </span>
                                        </b-td>
                                        <b-td class="align-middle">
                                            <b-button
                                                variant="outline-primary"
                                                @click.prevent="openContactModal(sensor)"
                                            >
                                                Contact
                                            </b-button>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                        <p class="clr-light-grey text-center" v-else>AUCUN</p>
                    </b-col>
                </b-row>
            </b-col>

            <!-- ONGOING EVENTS -->
            <b-col
                v-if="selectedTab === 'warning'"
                cols="12"
                class="my-2">
                <b-row class="basic-card h-100 no-gutters p-0" align-v="stretch">
                    <b-col cols="12">
                        <div class="table-title">
                            <p>Incidents en cours ({{ ongoingEvents.length }})</p>
                        </div>
                        <div v-if="eventsLoading"
                             class="table-responsive p-3"
                        >
                            <b-skeleton-table
                                :rows="5"
                                :columns="4"
                                :table-props="{ bordered: false, striped: true }"

                            ></b-skeleton-table>
                        </div>
                        <div class="table-responsive p-1" v-else-if="ongoingEvents.length > 0">
                            <b-table-simple responsive
                                            hover
                                            sticky-header
                                            class="table-borderless">
                                <b-thead class="clr-light-grey">
                                    <b-tr class="table-column-header-title">
                                        <b-th>Capteur</b-th>
                                        <b-th>Client</b-th>
                                        <b-th>Incident</b-th>
                                        <b-th>Depuis le</b-th>
                                        <b-th>Par</b-th>
                                        <b-th></b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr
                                        v-for="(event, index) in ongoingEvents"
                                        :key="`ongoing_events_${index}`"
                                        class="clr-darker-grey">
                                        <b-td class="add-cursor add-hover"
                                              @click="goToProfile(event.sensor.serialNumber)">
                                            <h6 class="mb-0 clr-orange">{{ event.sensor.serialNumber }}</h6>
                                        </b-td>
                                        <b-td class="add-cursor add-hover"
                                              @click="goToAccount(event.account.id)">
                                            <h6 class="mb-0 clr-orange">{{ event.account.name }}</h6>
                                        </b-td>
                                        <b-td>
                                            <p class="m-0">
                                                <span class="bold">{{ event.title }}</span>
                                                <br>{{ event.context }}
                                            </p>
                                        </b-td>
                                        <b-td>
                                            {{ event.createdAt | readableDate }}
                                        </b-td>
                                        <b-td>
                                            {{ event.user.email }}
                                        </b-td>
                                        <b-td class="align-middle">
                                            <b-button @click="showEvent(event)" variant="outline-dark">
                                                <b-icon icon="eye"/>
                                            </b-button>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                        <p class="clr-light-grey text-center" v-else>AUCUN</p>
                    </b-col>
                </b-row>
                <b-modal
                    hide-footer
                    hide-header
                    hide-header-close
                    id="event-details"
                    body-class="p-0"
                    size="xl">
                    <SensorEventDetails
                        v-if="selectedEvent"
                        :event="selectedEvent"
                        from="DashboardSensors"
                        :key="'selected_event_' + selectedEvent.id"/>
                </b-modal>
            </b-col>

            <!-- WAITING FOR INSTALLATION SENSORS -->
            <b-col
                v-if="selectedTab === 'deploy'"
                cols="12"
                md="5"
                class="my-2">
                <b-row class="basic-card h-100 no-gutters p-0" align-v="stretch">
                    <b-col cols="12">
                        <div class="table-title">
                            <p>En attente d'installation ({{ waitingForInstallationSensors.length }})</p>
                        </div>
                        <div
                            v-if="getLoadingStatus"
                            class="table-responsive p-3"
                        >
                            <b-skeleton-table
                                :rows="5"
                                :columns="4"
                                :table-props="{ bordered: false, striped: true }"

                            ></b-skeleton-table>
                        </div>
                        <div class="table-responsive p-1" v-else-if="waitingForInstallationSensors.length > 0">
                            <b-table-simple responsive
                                            hover
                                            sticky-header
                                            class="table-borderless"
                            >
                                <b-thead class="clr-light-grey">
                                    <b-tr class="table-column-header-title">
                                        <b-th>Capteurs</b-th>
                                        <b-th>Client</b-th>
                                        <b-th>Depuis le</b-th>
                                        <b-th></b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr
                                        v-for="(sensor, index) in waitingForInstallationSensors"
                                        :key="`waitingForInstallation_sensors_${index}`"
                                        class="clr-darker-grey add-cursor"
                                    >
                                        <b-td class="add-cursor add-hover"
                                              @click="goToProfile(sensor.serialNumber)"
                                        >
                                            <div class="d-flex px-1 py-1 justify-content-start">
                                                <h6 class="mb-0 clr-orange">{{ sensor.serialNumber }}</h6>
                                            </div>
                                        </b-td>
                                        <b-td class="add-cursor text-center">
                                            <div class="d-flex px-1 py-1 justify-content-start add-hover">
                                                <h6
                                                    v-if="sensor.dataReference &&
                                                     sensor.dataReference.payload &&
                                                      sensor.dataReference.payload.length > 0"
                                                    class="mb-0 clr-darker-grey">
                                                    {{ sensor.dataReference.payload[0]['account'] }}
                                                </h6>
                                                <h6 v-else class="mb-0 clr-darker-grey">- - -</h6>
                                            </div>
                                        </b-td>
                                        <b-td>
                                          <span class="text-secondary font-weight-bold clr-red">
                                            {{ sensor.sensorParameters.statusUpdate| dateDayMonth }}
                                          </span>
                                        </b-td>
                                        <b-td class="align-middle">
                                            <b-button
                                                variant="outline-primary"
                                                @click.prevent="openContactModal(sensor)"
                                            >
                                                Contact
                                            </b-button>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                        <p class="clr-light-grey text-center" v-else>AUCUN</p>
                    </b-col>
                </b-row>
            </b-col>

            <!-- IN TRANSIT SENSORS -->
            <b-col
                v-if="selectedTab === 'deploy'"
                cols="12"
                md="7"
                class="my-2">
                <b-row class="basic-card h-100 no-gutters p-0" align-v="stretch">
                    <b-col cols="12">
                        <div class="table-title">
                            <p>En Transit ({{ inTransitSensors.length + sensorTrackings.length }})</p>
                        </div>
                        <div class="table-responsive" v-if="inTransitSensors.length > 0 || sensorTrackings.length > 0">
                            <b-table-simple responsive
                                            hover
                                            sticky-header
                                            class="table-borderless">
                                <b-thead class="clr-light-grey">
                                    <b-tr class="table-column-header-title">
                                        <b-th>Capteurs associé(s)</b-th>
                                        <b-th>Statut</b-th>
                                        <b-th>Dernière MAJ</b-th>
                                        <b-th>Bon de Transport</b-th>
                                        <b-th>ACTION</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr
                                        v-for="(sensor, index) in inTransitSensors"
                                        :key="`sensor_transit_${index}`"
                                        class="clr-darker-grey"
                                    >
                                        <b-td>
                                            <div
                                                @click="goToProfile(sensor.serialNumber)"
                                                class="d-flex px-1 py-1 justify-content-start add-cursor">
                                                <h6 class="mb-0 clr-orange">{{ sensor.serialNumber }}</h6>
                                            </div>
                                        </b-td>
                                        <b-td class="caption"
                                              v-if="sensor.dataReference && sensor.dataReference.payload.length > 0">
                                            Installation par Lifyair pour
                                            {{ sensor.dataReference.payload[0]['account'] }}
                                        </b-td>
                                        <b-td v-else>
                                            - - -
                                        </b-td>
                                        <b-td>
                                          <span class="text-secondary font-weight-bold clr-red">
                                            - - -
                                          </span>
                                        </b-td>
                                        <b-td>
                                            <span>- - -</span>
                                        </b-td>
                                        <b-td>
                                            <b-dropdown
                                                size="sm"
                                                menu-class="clr-orange bg-white"
                                                toggle-class="clr-orange bg-white border-0"
                                                split-class="clr-orange bg-white"
                                                no-caret>
                                                <template #button-content>
                                                    <b-icon-three-dots-vertical class="clr-orange bg-white"/>
                                                </template>
                                                <b-dropdown-item @click="changeSensorStatus(sensor.serialNumber)">
                                                    Déclarer le colis comme "installé"
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-td>
                                    </b-tr>
                                    <b-tr
                                        v-for="(tracking, index) in inTransitTrackings"
                                        :key="`sensor_tracking_${index}`"
                                        class="clr-darker-grey"
                                    >
                                        <b-td>
                                            <div
                                                v-for="(sensor, i) in tracking.sensors"
                                                :key="'tracking_sensor_' + i"
                                                @click="goToProfile(sensor.serialNumber)"
                                                class="d-flex px-1 py-1 justify-content-start add-cursor"
                                            >
                                                <h6 class="mb-0 clr-orange">{{ sensor.serialNumber }}</h6>
                                            </div>
                                        </b-td>
                                        <b-td>{{ tracking.trackingStatus }}</b-td>
                                        <b-td>
                                          <span class="text-secondary font-weight-bold clr-red">
                                            {{ tracking.updatedAt | dateDayMonth }}
                                          </span>
                                        </b-td>
                                        <b-td>
                                            <span>{{ tracking.trackingNumber }}</span>
                                        </b-td>
                                        <b-td>
                                            <b-dropdown
                                                size="sm"
                                                menu-class="clr-orange bg-white"
                                                toggle-class="clr-orange bg-white border-0"
                                                split-class="clr-orange bg-white"
                                                no-caret>
                                                <template #button-content>
                                                    <b-icon-three-dots-vertical class="clr-orange bg-white"/>
                                                </template>
                                                <b-dropdown-item
                                                    active-class="bg-orange clr-white"
                                                    @click="sendToExternalLink('http://www.tnt.fr/public/suivi_colis/recherche/visubontransport.do?bonTransport=' + tracking.trackingNumber)">
                                                    Suivre le colis
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="changeTrackingStatus(tracking.id)">
                                                    Déclarer le colis comme "délivré"
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                        <p class="clr-light-grey text-center" v-else>AUCUN</p>
                    </b-col>
                </b-row>
            </b-col>

            <!-- TESTING SENSORS -->
            <b-col
                v-if="selectedTab === 'test'"
                cols="12"
                class="my-2">
                <b-row class="basic-card h-100 no-gutters p-0" align-v="stretch">
                    <b-col cols="12">
                        <div class="table-title">
                            <p>En cours de test ({{ sensorSetups.length }})</p>
                        </div>
                        <div class="table-responsive" v-if="sensorSetups.length > 0">
                            <b-table-simple responsive
                                            hover
                                            sticky-header
                                            class="table-borderless table-hover">
                                <b-thead class="clr-light-grey">
                                    <b-tr class="table-column-header-title">
                                        <b-th>Capteurs</b-th>
                                        <b-th>Étape</b-th>
                                        <b-th>Depuis le</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr
                                        v-for="(setup, index) in sensorSetups"
                                        :key="`sensor_setups_${index}`"
                                        class="clr-darker-grey add-cursor"
                                        @click="goToSensorTest(setup.sensor.serialNumber)"
                                    >
                                        <b-td>
                                            <div
                                                class="d-flex px-1 py-1 justify-content-start add-cursor"
                                            >
                                                <h6 class="mb-0 add-cursor clr-orange">
                                                    {{ setup.sensor.serialNumber }}
                                                </h6>
                                            </div>
                                        </b-td>
                                        <b-td>
                                            <p>{{ actualStepBySensorSetup(setup) }}</p>
                                        </b-td>
                                        <b-td>
                                          <span :class="colorByDate(setup.createdAt)">
                                            {{ setup.createdAt | dayDate }}
                                          </span>
                                        </b-td>
                                        <b-td class="text-center"
                                              @click="goToSensorTest(setup.sensor.serialNumber)">
                                            <b-button variant="primary">
                                                {{
                                                    actualStepBySensorSetup(setup) === 'Fin' ? 'À déployer' : 'Reprendre'
                                                }}
                                            </b-button>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                        <p class="clr-light-grey text-center" v-else>AUCUN</p>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <!--        SENSOR CONTACT MODAL-->
        <b-modal :ref="'contact-modal-'+modalSerialNumber"
                 :id="'contact-modal-'+modalSerialNumber"
                 hide-footer
                 scrollable
                 title="Contact(s) Renseigné(s)"
                 size="lg"
                 class="p-2 clr-darker-grey bg-white"
        >
            <b-container
                fluid
                v-if="modalContacts && modalContacts.length > 0">
                <b-row
                    class="shadow bg-lighter-grey p-3 my-2 justify-content-between"
                    v-for="(contact,i) in modalContacts"
                    :key="i">
                    <b-col
                        cols="12"
                        class="text-center mb-4">
                        <span class="container-title">
                            {{ contact.firstName | capitalize }} {{ contact.lastName | capitalize }}
                        </span>
                        <br>{{ contact.position }}
                    </b-col>
                    <b-col cols="6">
                        <span class="bold clr-darker-grey">
                            <b-icon icon="at"></b-icon>
                            Email
                        </span>
                        :
                        <a
                            target="_blank"
                            :href="`mailto:${contact.email}`"
                            class="clr-dark-grey">
                            {{ contact.email }}
                        </a>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <b-button
                            target="_blank"
                            :href="mailToContent(contact.email)"
                            variant="outline-primary">
                            Envoyer un mail de maintenance
                        </b-button>
                    </b-col>
                    <b-col cols="6">
                        <span class="bold clr-darker-grey">
                            <b-icon icon="phone"></b-icon>
                            Téléphone
                        </span>
                        :
                        <a
                            target="_blank"
                            :href="'tel:'+contact.phoneNumber"
                            class="clr-dark-grey">
                            {{ contact.phoneNumber }}
                        </a>
                    </b-col>
                </b-row>
            </b-container>
            <b-container fluid v-else>
                <b-row class="shadow bg-lighter-grey p-3 my-1">
                    <p>Aucun Contact répertorié pour le moment</p>
                </b-row>
            </b-container>
            <b-button
                class="mt-3 bg-orange clr-white"
                block
                @click="closeContactModal">
                Fermer
            </b-button>
        </b-modal>
    </b-col>
</template>

<script>
import Vuex from 'vuex'
import moment from "moment";
import dataFormatter from "@/services/utils/dataFormatter";
import SensorEventDetails from "@/components/sensor/Detail/SAV/SensorEventDetails.vue";

export default {
    name: "DashboardSensors",
    components: {SensorEventDetails},
    data() {
        return {
            modalContacts: null,
            modalSerialNumber: null,
            modalSensor: null,
            selectedTab: 'warning',
            viewMenu: false,
            top: '0px',
            left: '0px',
            rightClickedSensor: '',
            selectedEvent: null
        }
    },
    methods: {
        ...Vuex.mapActions('sensorSetup', {
            setSensorSetups: 'setSensorSetups',
        }),
        ...Vuex.mapActions('sensorList', {
            setSensorsByState: 'setSensorsByState',
            setDisconnectedSensors: 'setDisconnectedSensors',
            setDashboardLoading: 'setDashboardLoading',
        }),
        ...Vuex.mapActions('deliveryTracking', {
            setTrackings: 'setTrackings',
            updateTrackingStatus: 'updateTrackingStatus'
        }),
        ...Vuex.mapActions('monitoring', {
            setCurrentSensorMonitoring: 'setCurrentSensorMonitoring'
        }),
        ...Vuex.mapActions('sensor', {
            editSensor: 'editSensor'
        }),
        ...Vuex.mapActions('sensorEvent', {
            setOngoingEvents: 'setOngoingEvents'
        }),
        goToProfile(id) {
            window.open("/sensors/" + id + "/detail", '_blank');
        },
        goToSensorTest(id) {
            window.open("/sensors/testing/" + id, '_blank');
        },
        goToAccount(id) {
            window.open("/accounts/" + id + "/information", '_blank');
        },
        colorByDate(date) {
            const now = moment();
            const diff = moment(date).diff(now, 'days');
            if (diff > 6) {
                return 'clr-danger'
            } else if (diff > 3) {
                return 'clr-yellow'
            } else {
                return 'clr-green'
            }
        },
        sendToExternalLink(url) {
            let win = window.open(url, '_blank');
            win.focus();
        },
        changeTrackingStatus(id) {
            let form = new FormData();
            form.set('status', 'deploy')
            form.set('statusStep', '2')
            form.set('trackingStatus', 'delivered')
            this.updateTrackingStatus({id: id, data: form}).then(() => {
                this.setTrackings();
                this.setSensorsByState({state: 'waitingForInstallation', sort: 'sensor_parameters.statusUpdate'});
                this.setSensorsByState({state: 'inTransit'});
            })
        },
        openContactModalMonitoring(serialNumber, data) {
            this.modalContacts = data.account.accountContacts;
            this.modalSensor = {sensorAddress: data.address, sensorName: data.sensorName};
            this.modalSerialNumber = serialNumber;
            setTimeout(() => {
                this.$bvModal.show('contact-modal-' + serialNumber)
            }, 50)
        },
        openContactModal(sensor) {
            this.modalContacts = sensor.owningAccount.accountContacts;
            this.modalSensor = sensor;
            this.modalSerialNumber = sensor.serialNumber;
            setTimeout(() => {
                this.$bvModal.show('contact-modal-' + sensor.serialNumber)
            }, 50)
        },
        closeContactModal() {
            this.$bvModal.hide('contact-modal-' + this.modalSerialNumber)
            this.modalContacts = null
            this.modalSerialNumber = null
        },
        selectTab(tab) {
            this.selectedTab = tab;
        },
        sortByStatusUpdate(array) {
            if (array.length > 0) {
                return array.sort((a, b) => {
                    return moment(a.sensorParameters.statusUpdate) < moment(b.sensorParameters.statusUpdate) ? -1 : 1 // Sort Ascending
                })
            }
            return [];
        },
        setMenu: function (top, left) {
            this.top = (top - 267) + 'px';
            this.left = (left - 61) + 'px';
        },
        closeMenu: function () {
            this.viewMenu = false;
        },
        handler(event, data) {
            this.viewMenu = true;
            this.rightClickedSensor = data
            setTimeout(() => {
                this.$refs.right.focus();
                this.setMenu(event.y, event.x)
            }, 0);
            event.preventDefault();
        },
        mailToContent(email) {
            const subject = `Capteur Beenose - ${this.modalSensor.sensorName}`
            const locality = this.modalSensor.sensorAddress?.locality;
            const body = `
            Bonjour,

            Il semblerait que le capteur ${this.modalSerialNumber}, initialement installé à ${locality} présente un défaut.

            Afin de nous permettre de procéder à un diagnostic plus poussé, nous avons besoin que vous interveniez pour vérifier la source d’alimentation (disjoncteur, alimentation non-permanente, alimentation défectueuse, dysfonctionnement temporaire, etc.).

            Si tout semble correct, merci de débrancher et rebrancher le capteur afin de lever la possibilité d’un faux contact et de déclencher la réinitialisation du capteur.

            Si vous rencontrez des difficultés, n’hésitez pas à revenir vers moi.

            Je vous souhaite une excellente journée.

            Bien cordialement`

            return dataFormatter.mailToGenerator(email, subject, body)
        },
        async changeSensorStatus(sensor) {
            let data = []
            data.push(sensor)
            let fields = new FormData();
            fields.set('status', 'deploy')
            fields.set('statusStep', '2')

            data.push(fields)
            await this.editSensor(data).then(() => {
                this.setTrackings();
                this.setSensorsByState({state: 'inTransit'});
                this.setSensorsByState({state: 'waitingForInstallation', sort: 'sensor_parameters.statusUpdate'});
            });
        },
        showEvent(event) {
            this.selectedEvent = event;
            this.$bvModal.show('event-details')
        }
    },
    computed: {
        ...Vuex.mapGetters('sensorSetup', {
            sensorSetups: 'sensorSetups',
            steps: 'steps',
            actualStepBySensorSetup: 'actualStepBySensorSetup',
        }),
        ...Vuex.mapGetters('deliveryTracking', {
            sensorTrackings: 'sensorTrackings',
        }),
        ...Vuex.mapGetters('sensorList', {
            sensorsByState: 'sensorsByState',
            getLoadingStatus: 'getLoadingStatus',
        }),
        ...Vuex.mapGetters('monitoring', {
            getCurrentSensorMonitoring: 'getCurrentSensorMonitoring',
            isSensorMonitoringLoading: 'isSensorMonitoringLoading',
        }),
        ...Vuex.mapGetters('sensorEvent', {
            ongoingEvents: 'ongoingEvents',
            eventsLoading: 'isLoading',
        }),
        waitingForInstallationSensors() {
            return this.sortByStatusUpdate(this.sensorsByState('waitingForInstallation'));
        },
        inTransitSensors() {
            const serialNumbers = this.sensorTrackings.map(item => item.sensors.map(sensor => sensor.serialNumber)).flat();
            const sensorsWithoutTracks = this.sensorsByState('inTransit')
                .filter(item => !serialNumbers.includes(item.serialNumber))
            return this.sortByStatusUpdate(sensorsWithoutTracks);
        },
        inTransitTrackings() {
            return this.sensorTrackings.filter(tracking => !tracking.trackingStatus.includes('delivered'))
        },
        disconnectedSensors() {
            let sensors = this.sensorsByState('disconnected');
            if (sensors.length > 0) {
                sensors = sensors.sort((a, b) => {
                    return moment(a.lastUpdate) < moment(b.lastUpdate) ? -1 : 1 // Sort Ascending
                })
                sensors = sensors.filter(sensor => sensor.sensorParameters.statusStep !== 3)
                return sensors;
            }
            return [];
        },
        filteredWarningSensors() {
            const eventSerialNumbers = this.ongoingEvents.map(item => item.sensor.serialNumber);
            const filteredSensors = {};
            for (const key in this.getCurrentSensorMonitoring) {
                if (!eventSerialNumbers.includes(key)) {
                    const errors = this.getCurrentSensorMonitoring[key].errors;
                    const errorTypes = Object.keys(errors);

                    if (errorTypes.some(errorType => errorType !== "ERROR_DISCONNECTION" && errorType !== "ERROR_KO")) {
                        filteredSensors[key] = this.getCurrentSensorMonitoring[key];
                    }
                }
            }
            return filteredSensors
        }
    },
    async created() {
        await this.setDashboardLoading(true)
        await this.setDisconnectedSensors({
            status: 'prod',
            type: 'pollen',
            lastUpdate: moment().subtract(24, 'h').format('YYYY-MM-DD')
        });
        await this.setSensorSetups({type: 'inProgress'});
        await this.setCurrentSensorMonitoring()
        await this.setSensorsByState({state: 'waitingForInstallation'});
        await this.setOngoingEvents()
        await this.setSensorsByState({state: 'inTransit'});
        await this.setTrackings();
        await this.setDashboardLoading(false)
    },
}
</script>

<style lang="scss" scoped>
.table-column-header-title {
    font-size: .75rem;
    font-weight: bold;
    text-transform: uppercase;

    & > th {
        color: $light-grey !important;
    }

    border-color: rgba(0, 0, 0, 0) !important;
}

table > * {
    border-color: rgba(0, 0, 0, 0) !important;
}

.table-link {
    color: $orange-lifyair;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.table-title {
    padding: 20px 0 0 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: $darker-grey;
}

::-webkit-scrollbar {
    width: 10px; /* Adjust as needed */
    padding: 0 10px 0 10px;
}

::-webkit-scrollbar-thumb {
    background-color: $orange-lifyair; /* Thumb color */
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background-color: $lighter-grey; /* Track color */
    border-radius: 10px;
}

.dashboard-tabs {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    border-radius: 0.25rem;
    color: $black;
    background-color: $white;
    text-transform: uppercase;
    text-align: center;
    padding-top: 20px;
    cursor: pointer;
    margin-right: 5px;

    &.active {
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        background-color: $white;
        color: $orange-lifyair;
        border-bottom: 3px solid $orange-lifyair;
        font-weight: bold;
    }
}
</style>
