<template>
    <b-row class="justify-content-center align-content-center">
        <b-col cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Modifier un utilisateur
            </h2>
            <b-form @submit.prevent="submit()" class="mb-3 px-1 px-md-5">
                <b-form-group
                    label-for="email"
                    description="Email de l'utilisateur à éditer"
                >
                    <b-input-group size="sm" class="mb-2">
                        <b-input-group-prepend is-text>
                            Email
                        </b-input-group-prepend>
                        <b-form-input
                            id="email"
                            v-model="email"
                            type="email"
                            required
                            placeholder="client@client.com"
                        ></b-form-input>
                    </b-input-group>
                </b-form-group>
                <b-form-group
                    label-for="password"
                    description="Mot de passe Aléatoire en cliquant sur les fléches"
                >
                    <b-input-group size="sm" class="mb-2">
                        <b-input-group-prepend is-text>
                            Password
                        </b-input-group-prepend>
                        <b-form-input
                            id="password"
                            v-model="password"
                            placeholder="*************"
                        ></b-form-input>
                        <b-input-group-append is-text>
                            <b-icon icon="arrow-repeat" @click="generateRandomPassword(8)"></b-icon>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group
                    id="input-group-3"
                    label-for="input-3"
                    v-if="getUser.roles && optionsRoles.length !== getUser.roles.length"
                    description="Role(s) disponible(s) pour l'utilisateur"
                >
                    <p class="">Roles acquis :
                        <span v-for="(role,i) in getUser.roles" :key="i">
                        <b-badge pill variant="primary">{{ role }}</b-badge>
                    </span>
                    </p>

                    <b-form-select v-model="roles" :options="optionsRoles" class="mb-3">
                        <b-input-group-prepend is-text>
                            Roles
                        </b-input-group-prepend>
                    </b-form-select>
                </b-form-group>
                <b-form-group v-else>
                    <p class="font-weight-bolder">L'utilisateurs possède déja tous les roles disponibles <br>
                        <span v-for="(role,i) in getUser.roles" :key="i" class="clr-dark-blue">
                        <b-badge pill variant="primary">{{ role }}</b-badge>
                    </span>
                    </p>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="$router.go(-1)">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button class="btn-action" @click="submit">Valider</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'
import generator from "@/utils/generator";

export default {
    name: "UserEdit",
    data() {
        return {
            password: null,
            email: null,
            roles: null,
            optionsRoles: [
                {value: null, text: 'Default'},
                {value: 'ROLE_ADMIN', text: 'Administrateur Lifyair'},
                {value: 'ROLE_LIFYAIR_COLLABORATOR', text: 'Collaborateur Lifyair'},
                {value: 'ROLE_ACCOUNT_MANAGER', text: 'Gérant du compte'},
                {value: 'ROLE_COLLABORATOR', text: 'Collaborateur du compte'},
                {value: 'ROLE_API', text: 'Accès à l\'API Pollen'},
                {value: 'ROLE_LIVE_POLLEN_PRO', text: 'Accès au superviseur pollen'},
                {value: 'ROLE_API_POLLUTION', text: 'Accès à l\'API pollution'},
                {value: 'ROLE_LIVE_POLLUTION', text: 'Accès au superviseur pollution'},
                {value: 'ROLE_SCIENTIFIC_PARTNER', text: 'Accès aux données à plus spécifiques'},
            ],
        }
    },
    methods: {
        ...Vuex.mapActions('user', {
            editUser: 'editUser',
            setUser: 'setUser'
        }),
        async submit() {
            let query = this.generateQueryString()
            await this.editUser(query).then(() => {
                this.goToUserProfile();
            });
        },
        generateQueryString() {
            const data = [this.$route.params.id];
            const fields = new FormData();
            if (this.email !== this.getUser) {
                fields.set('email', this.email)
            }

            if (this.password) {
                fields.set('password', this.password)
            }

            if (this.roles !== null && (this.getUser && !this.getUser.roles.includes(this.roles)) && this.roles !== 'ROLE_USER') {
                fields.set('roles[]', this.roles)
            }

            data.push(fields)
            return data
        },
        goToUserProfile() {
            this.$router.push('/users/' + this.$route.params.id + '/information');
        },
        generateRandomPassword(l = 8) {
            this.password = generator.generatePassword(l)
        },
    },
    computed: {
        ...Vuex.mapGetters('user', {
            getUser: 'getUser'
        }),
    },
    async created() {
        await this.setUser(this.$route.params.id);
        if (!this.getUser) {
            this.$router.go(-1)
        }
        this.email = this.getUser.email
    },
}
</script>

<style lang="scss" scoped>

</style>
