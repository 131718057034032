<template>
    <b-row class="px-3 pt-2">

        <!-- TABS -->
        <b-col cols="12" md="2" class="bg-white shadow rounded mb-md-0 mb-3">
            <b-row class="p-2 align-items-center justify-content-start justify-content-md-center">
                <b-col cols="12" class="d-none d-md-block">
                    <h4 class="text-center clr-dark-grey p-2">Menu </h4>
                </b-col>
                <!-- Use a loop to generate the menu items -->
                <b-col v-for="item in menuItems"
                       :key="item.key"
                       cols="6"
                       md="12"
                       class="p-2 d-flex d-inline-block mb-1 add-cursor"
                       :class="item.component === dynamicComponent ? `bg-lighter-grey clr-${sensorType} border-right-${sensorType}` : ''"
                       @click="switchTo(item.component)"
                >
                      <span
                          :class="`bg-${sensorType}`"
                          class="rounded icon-square-small d-flex justify-content-center align-items-center mr-1">
                        <b-icon :icon="item.icon" class="clr-white" scale="1"></b-icon>
                      </span>
                    <h6 class="text-capitalize my-auto ml-1">{{ item.title }}</h6>
                </b-col>
            </b-row>
        </b-col>


        <!-- TAB CONTENT -->
        <b-col cols="12" md="10">
            <div class="justify-content-between shadow rounded align-items-center text-center"
                 v-if="isViabilityLoading">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
            </div>
            <b-row class="px-md-3 pb-5 scrollable-content h-100" v-else>
                <component :is="dynamicComponent" v-bind="currentProperties"></component>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import SensorMonitoring from "@/components/sensor/Detail/SensorMonitoring.vue";
import PDFTemplate from "@/components/pdf/PDFTemplate.vue";
import StepProgress from "vue-step-progress";
import SensorSIMConsumptionChart from "@/components/chart/SensorSIMConsumptionChart.vue";
import SensorSIMConsumptionDaily from "@/components/chart/SensorSIMConsumptionDaily.vue";
import Timeline from "@/components/sensor/Detail/Timeline.vue";
import SensorFollowUp from "@/components/sensor/Detail/SensorFollowUp.vue";
import SensorInformations from "@/components/sensor/Detail/SensorInformations.vue";
import SensorTools from "@/components/sensor/Detail/SensorTools.vue";
import SensorLiveDataPreview from "@/components/sensor/Detail/SensorLiveDataPreview.vue";
import S3FileViewer from "@/components/sensor/Detail/S3FileViewer.vue";
import SensorHistory from "@/components/sensor/Detail/SensorHistory.vue";
import SensorSetupHub from "@/components/sensor/Detail/SensorSetupHub.vue";
import SensorSetupList from "@/components/sensor/Detail/SensorSetupList.vue";
import SensorEvent from "@/components/sensor/Detail/SAV/SensorEvent.vue";

export default {
    name: 'SensorSideMenu',
    props: {
        currentProperties: Object
    },
    components: {
        SensorMonitoring,
        PDFTemplate,
        StepProgress,
        SensorSIMConsumptionChart,
        SensorSIMConsumptionDaily,
        Timeline,
        SensorFollowUp,
        SensorInformations,
        SensorTools,
        SensorLiveDataPreview,
        SensorHistory,
        SensorSetupHub,
        SensorSetupList,
        SensorEvent,
        S3FileViewer
    },
    data() {
        return {
            dynamicComponent: 'SensorFollowUp', // Set the default component
            isViabilityLoading: false, // Assuming you have this property defined elsewhere
            menuItems: [
                {
                    key: 'SensorFollowUp',
                    component: 'SensorFollowUp',
                    icon: 'wifi',
                    title: 'Monitoring',
                },
                {
                    key: 'SensorEvent',
                    component: 'SensorEvent',
                    icon: 'tools',
                    title: 'SAV',
                },
                {
                    key: 'SensorHistory',
                    component: 'SensorHistory',
                    icon: 'file-earmark-easel',
                    title: 'Historique',
                },
                {
                    key: 'SensorLiveDataPreview',
                    component: 'SensorLiveDataPreview',
                    icon: 'display',
                    title: 'Données Live',
                },
                {
                    key: 'S3FileViewer',
                    component: 'S3FileViewer',
                    icon: 'display',
                    title: 'Données Live2',
                },
                {
                    key: 'SensorInformations',
                    component: 'SensorInformations',
                    icon: 'box',
                    title: 'Informations',
                },
                {
                    key: 'SensorSetupList',
                    component: 'SensorSetupList',
                    icon: 'clipboard-check',
                    title: 'Historique test',
                },
                {
                    key: 'SensorTools',
                    component: 'SensorTools',
                    icon: 'wrench',
                    title: 'Outils',
                },
                {
                    key: 'SensorSetupHub',
                    component: 'SensorSetupHub',
                    icon: 'gear-fill',
                    title: 'Paramètres',
                },
            ],
        };
    },
    methods: {
        switchTo(component) {
            this.dynamicComponent = component;
        },
    },
    computed: {
        sensorType() {
            if (!this.currentProperties.actualSensor) return 'pollen'
            return this.currentProperties.actualSensor.type.toLowerCase();
        }
    }
};
</script>

<style lang="scss" scoped>

.icon-square {
    width: 2.5em;
    height: 2.5em;
}


.icon-square-big {
    width: 3.5em;
    height: 3.5em;
}

.icon-square-small {
    width: 1.5em;
    height: 1.5em;
}

.scrollable-content { /* Set the maximum height as per your requirement */
    max-height: 67vh;
    overflow: scroll; /* Hide the scrollbar */
}

/* Add custom styling to the content-wrapper to enable scrolling */
.content-wrapper {
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* For Firefox */
.scrollable-content::-webkit-scrollbar {
    display: none;
}

.border-right-pollen {
    border-right: 2px solid $orange-lifyair;
}
.border-right-pollution {
    border-right: 2px solid $live-pollution;
}
</style>
