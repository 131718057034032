var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"justify-content-center align-content-center"},[_c('b-col',{staticClass:"basic-card",attrs:{"cols":"12","md":"8"}},[_c('h2',{staticClass:"mb-5 text-center"},[_vm._v(" Gestion des rôles utilisateurs ")]),_c('b-form',{staticClass:"mb-3 px-1 px-md-5",on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('b-form-group',{attrs:{"label":"Global:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"stacked":"","options":_vm.globalRoles,"aria-describedby":ariaDescribedby,"name":"roles-list"},model:{value:(_vm.chosenRoles),callback:function ($$v) {_vm.chosenRoles=$$v},expression:"chosenRoles"}})]}}])}),_c('b-form-group',{attrs:{"label":"Pollen:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"stacked":"","options":_vm.pollenRoles,"aria-describedby":ariaDescribedby,"name":"roles-list"},model:{value:(_vm.chosenRoles),callback:function ($$v) {_vm.chosenRoles=$$v},expression:"chosenRoles"}})]}}])}),_c('b-form-group',{attrs:{"label":"Pollution:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"stacked":"","options":_vm.pollutionRoles,"aria-describedby":ariaDescribedby,"name":"roles-list"},model:{value:(_vm.chosenRoles),callback:function ($$v) {_vm.chosenRoles=$$v},expression:"chosenRoles"}})]}}])}),(_vm.isAdmin)?_c('b-form-group',{attrs:{"label":"Lifyair:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"stacked":"","options":_vm.lifyairRoles,"aria-describedby":ariaDescribedby,"name":"roles-list"},model:{value:(_vm.chosenRoles),callback:function ($$v) {_vm.chosenRoles=$$v},expression:"chosenRoles"}})]}}],null,false,715222466)}):_vm._e()],1),_c('b-row',{staticClass:"justify-content-end mt-5 mr-2"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"btn-cancel",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Annuler")])],1),_c('b-col',{attrs:{"cols":"auto"}},[(_vm.isLoading)?_c('b-button',{staticClass:"btn-action"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('b-button',{staticClass:"btn-action",on:{"click":_vm.submit}},[_vm._v("Valider")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }