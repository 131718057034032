<template>
    <b-col sm="12" class="p-0 m-0">
        <div class="rounded bg-white shadow p-3 h-100" v-if="asked && !dynamoValues && !isLoading">
            <b-row class="justify-content-center align-content-center">
                <b-col cols="12" class="clr-darker-grey bg-lighter-grey text-center">
                    Il n'y a pas assez de données pour Afficher un Résultat DynamoDB
                </b-col>
            </b-row>
        </div>
        <div v-else class="rounded bg-white shadow p-3 h-100">
            <b-row>
                <div class="my-5 col-xl-12 text-center" v-if="actualSensor">
                    <b-button @click="getDataFromDynamoDb(20)" :class="`bg-${sensorType} clr-white`"
                              :active="!isLoading" v-if="!dynamoValues">
                        <span v-if="!isLoading">Accéder aux données Live</span>
                        <span v-else><b-spinner></b-spinner>Loading...</span>
                    </b-button>
                    <div class="font-weight-bold" v-else>
                        <p>
                            Données Chargées
                        </p>
                        <span>
                      <b-icon icon="check-circle" scale="2" class="clr-green"></b-icon>
                    </span>
                    </div>
                </div>
            </b-row>
            <b-row v-if="dynamoValues">
                <b-col cols="12" v-for="(item,i) in dynamoValues" :key="i" class="mb-xl-2">
                    <h2 class="clr-dark-grey">Données DynamoDB du {{ item.date.S | basicDate }}</h2>
                    <div class="card-text mt-2">
                        <p class="font-weight-bold clr-light-grey">Données Environnementales Instantanées
                            :
                        </p>
                        <b-table-simple hover small caption-top responsive>
                            <b-thead head-variant="dark">
                                <b-tr>
                                    <b-th colspan="2">Temperature (°C)</b-th>
                                    <b-th colspan="2">Humidity (%)</b-th>
                                    <b-th colspan="2">Altitude (m)</b-th>
                                    <b-th colspan="2">GPS Localisation</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td colspan="2"> {{ item.temperature.N }}</b-td>
                                    <b-td colspan="2"> {{ item.humidity.N }}</b-td>
                                    <b-td colspan="2"> {{ item.altitude.N }}</b-td>
                                    <b-td colspan="2"> [{{ item.latitude.N }} ,
                                        {{ item.longitude.N }}]
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                        <br>
                        <p class="font-weight-bolder">Données des Voies de Flux: </p>
                        <b-table-simple hover small caption-top responsive>
                            <b-thead head-variant="dark">
                                <b-tr>
                                    <b-th colspan="2"></b-th>
                                    <b-th colspan="2">Gamme</b-th>
                                    <b-th colspan="2">Voie 1</b-th>
                                    <b-th colspan="2">Voie 2</b-th>
                                    <b-th colspan="2">Voie 3</b-th>
                                    <b-th colspan="2">Voie 4</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-th colspan="2"> Offset</b-th>
                                    <b-th colspan="2"></b-th>
                                    <b-td colspan="2"> {{ item.offset_voie15.N }}</b-td>
                                    <b-td colspan="2"> {{ item.offset_voie60.N }}</b-td>
                                    <b-td colspan="2"> {{ item.offset_voie125.N }}</b-td>
                                    <b-td colspan="2"> {{ item.offset_voie160.N }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th colspan="2">Values</b-th>
                                    <b-td colspan="2">
                                        <span class="d-block">G1</span>
                                        <span class="d-block">G2</span>
                                        <span class="d-block">G3</span>
                                        <span class="d-block">G4</span>
                                        <span class="d-block">G5</span>
                                        <span class="d-block">G6</span>
                                        <span class="d-block">G7</span>
                                        <span class="d-block">G8</span>
                                        <span class="d-block">G9</span>
                                        <span class="d-block">G10</span>
                                        <span class="d-block">G11</span>
                                        <span class="d-block">G12</span>
                                        <span class="d-block">G13</span>
                                        <span class="d-block">G14</span>
                                        <span class="d-block">G15</span>
                                        <span class="d-block">G16</span>
                                        <span class="d-block">G17</span>
                                        <span class="d-block">G18</span>
                                        <span class="d-block">G19</span>
                                    </b-td>
                                    <b-td colspan="2">
                                        <span class="d-block">{{ item.gamme1_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme2_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme3_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme4_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme5_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme6_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme7_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme8_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme9_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme10_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme11_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme12_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme13_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme14_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme15_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme16_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme17_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme18_voie15.N }}</span>
                                        <span class="d-block">{{ item.gamme19_voie15.N }}</span>
                                    </b-td>
                                    <b-td colspan="2">
                                        <span class="d-block">{{ item.gamme1_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme2_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme3_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme4_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme5_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme6_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme7_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme8_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme9_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme10_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme11_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme12_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme13_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme14_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme15_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme16_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme17_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme18_voie60.N }}</span>
                                        <span class="d-block">{{ item.gamme19_voie60.N }}</span>
                                    </b-td>
                                    <b-td colspan="2">
                                        <span class="d-block">{{ item.gamme1_voie125.N }}</span>
                                        <span class="d-block">{{ item.gamme2_voie125.N }}</span>
                                        <span class="d-block">{{ item.gamme3_voie125.N }}</span>
                                        <span class="d-block">{{ item.gamme4_voie125.N }}</span>
                                        <span class="d-block">{{ item.gamme5_voie125.N }}</span>
                                        <span class="d-block">{{ item.gamme6_voie125.N }}</span>
                                        <span class="d-block">{{ item.gamme7_voie125.N }}</span>
                                        <span class="d-block">{{ item.gamme8_voie125.N }}</span>
                                        <span class="d-block">{{ item.gamme9_voie125.N }}</span>
                                        <span class="d-block">{{
                                                item.gamme10_voie125.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme11_voie125.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme12_voie125.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme13_voie125.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme14_voie125.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme15_voie125.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme16_voie125.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme17_voie125.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme18_voie125.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme19_voie125.N
                                            }}</span>
                                    </b-td>
                                    <b-td colspan="2">
                                        <span class="d-block">{{ item.gamme1_voie160.N }}</span>
                                        <span class="d-block">{{ item.gamme2_voie160.N }}</span>
                                        <span class="d-block">{{ item.gamme3_voie160.N }}</span>
                                        <span class="d-block">{{ item.gamme4_voie160.N }}</span>
                                        <span class="d-block">{{ item.gamme5_voie160.N }}</span>
                                        <span class="d-block">{{ item.gamme6_voie160.N }}</span>
                                        <span class="d-block">{{ item.gamme7_voie160.N }}</span>
                                        <span class="d-block">{{ item.gamme8_voie160.N }}</span>
                                        <span class="d-block">{{ item.gamme9_voie160.N }}</span>
                                        <span class="d-block">{{
                                                item.gamme10_voie160.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme11_voie160.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme12_voie160.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme13_voie160.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme14_voie160.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme15_voie160.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme16_voie160.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme17_voie160.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme18_voie160.N
                                            }}</span>
                                        <span class="d-block">{{
                                                item.gamme19_voie160.N
                                            }}</span>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </b-col>
            </b-row>
        </div>
    </b-col>
</template>

<script>
import AWS from "@/services/aws/dynamodb";

export default {
    name: "SensorLiveDataPreview",
    props: {
        actualSensor: Object,
    },
    data() {
        return {
            isLoading: false,
            dynamoValues: null,
            asked: false
        }
    },
    computed: {
        sensorType() {
            if (!this.actualSensor) return 'pollen'
            return this.actualSensor.type.toLowerCase();
        }
    },
    methods: {
        async getDataFromDynamoDb(limite = 100, order = false) {
            this.asked = true;
            this.isLoading = true
            let dynamoDB = AWS.dynamoDB;

            let params = {
                TableName: "raw-data-BU",
                KeyConditionExpression: "id = :id",
                ExpressionAttributeValues: {
                    ":id": {'S': this.actualSensor.serialNumber}
                },
                Limit: limite,
                ScanIndexForward: order,
            };
            await dynamoDB.query(params, (err, data) => {
                if (err) {
                    console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
                } else {
                    if (data.Items.length > 0) {
                        this.dynamoValues = data.Items;
                    } else {
                        this.dynamoValues = {};
                    }
                    this.isLoading = false
                }
            });
        },
    },
}
</script>


<style scoped lang="scss">

</style>