<template>
    <b-col sm="12" class="p-0 m-0">
        <b-card>
            <b-card-title>Consultez les fichiers S3</b-card-title>
            <b-card-body>
                <b-form>
                    <b-form-group
                        label="ID du capteur :"
                        v-if="sensorId"
                        label-for="sensorId"
                    >
                        <b-form-input
                            id="sensorId"
                            v-model="sensorId"
                            type="text"
                            placeholder="Entrez l'ID du capteur"
                        />
                    </b-form-group>

                    <b-form-group
                        label="Version du capteur :"
                        v-if="sensorVersion"
                        label-for="sensorVersion"
                    >
                        <b-form-select
                            id="sensorVersion"
                            v-model="sensorVersion"
                            :options="versionOptions"
                        />
                    </b-form-group>

                    <b-button
                        :class="`bg-${sensorType.toLowerCase()}`"
                        class="text-white"
                        @click="fetchFiles"
                        :disabled="isLoading"
                    >
                        <span v-if="!isLoading">Voir les fichiers</span>
                        <b-spinner small v-else label="Chargement..."/>
                    </b-button>
                </b-form>

                <b-alert
                    variant="danger"
                    v-if="error"
                    dismissible
                    class="mt-3"
                >
                    {{ error }}
                </b-alert>

                <div v-if="files.length" class="mt-4">
                    <h5>Fichiers disponibles :</h5>
                    <b-list-group>
                        <b-list-group-item
                            v-for="file in paginatedFiles"
                            :key="'file_key_' + file.Key"
                            class="d-flex justify-content-between align-items-center"
                        >
                            <!-- Nom du fichier et date -->
                            <div>
                                <span>{{ file.Key }}</span><br/>
                                <small class="text-muted">{{ formatDate(file.LastModifiedISO) }}</small>
                            </div>
                            <!-- Boutons alignés à droite -->
                            <div class="ml-auto">
                                <b-button
                                    size="sm"
                                    class="mr-2 bg-white"
                                    :class="`clr-${sensorType.toLowerCase()}`"
                                    @click="() => {
                            callModal();
                            previewFile(file.Key);
                        }"
                                    :disabled="isPreviewLoading"
                                >
                                    <b-spinner v-if="isPreviewLoading && file.Key === chosenKey" small></b-spinner>
                                    Prévisualiser
                                </b-button>
                                <b-button
                                    class="text-white"
                                    :class="`bg-${sensorType.toLowerCase()}`"
                                    size="sm"
                                    @click="downloadFile(file.Key)"
                                >
                                    Télécharger
                                </b-button>
                            </div>
                        </b-list-group-item>
                    </b-list-group>

                    <!-- Pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="files.length"
                        :per-page="itemsPerPage"
                        align="center"
                        class="mt-3"
                    ></b-pagination>
                </div>
                <b-modal
                    id="preview-modal"
                    title="Prévisualisation du fichier"
                    @hide="clearPreview"
                    size="xl"
                    scrollable
                >
                    <b-spinner v-if="isPreviewLoading" label="Chargement des Données"/>

                    <div v-for="(item, index) in previewContent" :key="'preview_content' + index" class="mb-4">
                        <b-card>
                            <!-- Date et Identifiant -->
                            <b-card-title class="d-flex align-items-center">
                                <b-icon icon="clock-fill" class="mr-2"></b-icon>
                                {{ formatDate(item.Date) }}
                                <b-badge
                                    :class="`bg-${sensorType.toLowerCase()}`"
                                    class="ml-auto text-white">
                                    {{ item.ID }}
                                </b-badge>
                            </b-card-title>

                            <!-- Données GPS -->
                            <b-card-text>
                                <b-icon icon="geo-alt-fill" class="mr-2"></b-icon>
                                <strong>Position :</strong>
                                <a
                                    :href="`https://www.google.com/maps?q=${item.Latitude},${item.Longitude}`"
                                    target="_blank"
                                    class="text-decoration-none bold"
                                    :class="`clr-${sensorType.toLowerCase()}`"
                                >
                                    {{ item.Latitude }}, {{ item.Longitude }}
                                </a>
                            </b-card-text>

                            <!-- Données Environnementales -->
                            <b-card-text>
                                <b-list-group horizontal>
                                    <b-list-group-item>
                                        <b-icon icon="cloud-fill" class="mr-2"></b-icon>
                                        <strong>Pression :</strong> {{ item.Pressure }} hPa
                                    </b-list-group-item>
                                    <b-list-group-item>
                                        <b-icon icon="thermometer-half" class="mr-2"></b-icon>
                                        <strong>Température :</strong> {{ item.Temperature }} °C
                                    </b-list-group-item>
                                    <b-list-group-item>
                                        <b-icon icon="droplet-fill" class="mr-2"></b-icon>
                                        <strong>Humidité :</strong> {{ item.Humidity }} %
                                    </b-list-group-item>
                                </b-list-group>
                            </b-card-text>

                            <!-- Trame de Données -->
                            <b-card-text>
                                <h5>Trame des Données</h5>
                                <div style="overflow-x: auto;">
                                    <b-table-simple hover small caption-top responsive>
                                        <b-thead head-variant="dark">
                                            <b-tr>
                                                <b-th colspan="2"></b-th>
                                                <b-th colspan="2">Gamme</b-th>
                                                <b-th colspan="2">Voie 1</b-th>
                                                <b-th colspan="2">Voie 2</b-th>
                                                <b-th colspan="2">Voie 3</b-th>
                                                <b-th colspan="2">Voie 4</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <!-- OFFSET -->
                                            <b-tr>
                                                <b-th class="text-left" colspan="2">Offset</b-th>
                                                <b-th colspan="2"></b-th>
                                                <b-td
                                                    colspan="2"
                                                    :key="offsetIndex + '_offset'"
                                                    v-for="(value, offsetIndex) in dataByVersion.offset">
                                                    {{ item.data[value] }}
                                                </b-td>
                                            </b-tr>

                                            <!-- NOISE -->
                                            <b-tr v-if="sensorVersion === 'v3' ||
                                             (sensorType.toLowerCase() === 'pollution' && sensorVersion === 'v2')">
                                                <b-th class="text-left" colspan="2"> Bruit</b-th>
                                                <b-th colspan="2"></b-th>
                                                <b-td
                                                    colspan="2"
                                                    :key="noiseIndex + '_noise'"
                                                    v-for="(value, noiseIndex) in dataByVersion.noise">
                                                    {{ item.data[value] }}
                                                </b-td>
                                            </b-tr>

                                            <!-- DATA -->
                                            <b-tr>
                                                <b-th class="text-left" colspan="2">Values</b-th>

                                                <!-- GAMMES -->
                                                <b-td colspan="2">
                                                    <span
                                                        :key="n + '_gamme'"
                                                        v-for="n in dataByVersion.gammes"
                                                        class="d-block">
                                                        G{{n}}
                                                    </span>
                                                </b-td>

                                                <!-- DATA PAR GAMMES -->
                                                <b-td
                                                    :key="dataIndex + '_' + range.key + 'data_by_gam'"
                                                    v-for="(range, dataIndex) in dataByVersion.dataRange"
                                                    colspan="2">
                                                    <span
                                                        :key="range.key + '_' + rangeIndex + '_data'"
                                                        v-for="rangeIndex in generateRange(range.start, range.end)"
                                                        class="d-block">
                                                        {{ item.data[rangeIndex] }}
                                                    </span>
                                                </b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </div>
                            </b-card-text>
                        </b-card>
                    </div>
                    <h5 class="text-center" :class="`clr-${sensorType.toLowerCase()}`">
                        Pour voir plus, téléchargez directement le fichier.
                    </h5>
                </b-modal>
            </b-card-body>
        </b-card>
    </b-col>
</template>

<script>
import AWS from "aws-sdk";

const pako = require('pako');
const fzstd = require('fzstd');

export default {
    name: "S3FileViewer",
    props: {
        actualSensor: Object,
    },
    data() {
        return {
            sensorId: this.actualSensor.serialNumber || "",
            sensorVersion: this.actualSensor.sensorVersion || "v2",
            sensorType: this.actualSensor.type || "pollen",
            versionOptions: [
                {value: "v1", text: "v1"},
                {value: "v2", text: "v2"},
                {value: "v3", text: "v3"},
            ],
            files: [],
            error: null,
            isLoading: false,
            previewContent: null,
            detailedData: null,
            isPreviewLoading: false,
            chosenKey: null,
            currentPage: 1,
            itemsPerPage: 10,
        };
    },
    methods: {
        getBucketAndPrefix() {
            const type = this.actualSensor.type.toLowerCase();
            const typeMapping = {
                pollution: {v1: "LC1", v2: "LC2"},
                pollen: {v2: "BN2", v3: "BN3"},
            };

            const bucket = this.sensorVersion === 'v3' || (type === 'pollution' && this.sensorVersion === 'v2') ? "laml-raw-data-storage" : "lifyair-iot-core-logs";
            const prefix = this.sensorVersion === 'v3' || (type === 'pollution' && this.sensorVersion === 'v2')
                ? `${type}/${typeMapping[type][this.sensorVersion]}/${this.sensorId}/` : `${type === 'pollen' ? 'beenose' : 'loac-auto'}/${this.sensorId}/`;
            return {bucket, prefix};
        },
        getFileExtension(filename) {
            return filename.split('.').pop();
        },
        async fetchFiles() {
            try {
                this.error = null;
                this.files = [];
                this.isLoading = true;

                if (!this.sensorId) {
                    this.error = "Veuillez entrer un ID de capteur.";
                    this.isLoading = false;
                    return;
                }

                const {bucket, prefix} = this.getBucketAndPrefix();
                const s3 = new AWS.S3();
                let isTruncated = true;
                let continuationToken = null;

                while (isTruncated) {
                    const params = {
                        Bucket: bucket,
                        Prefix: prefix,
                        ContinuationToken: continuationToken, // Pour récupérer la page suivante
                    };

                    const data = await s3.listObjectsV2(params).promise();

                    if (data.Contents && data.Contents.length > 0) {
                        // Ajoute les fichiers à la liste et trie par date décroissante
                        this.files = [
                            ...this.files,
                            ...data.Contents.map((file) => ({
                                ...file,
                                LastModifiedISO: file.LastModified
                                    ? new Date(file.LastModified).toISOString()
                                    : null,
                            })),
                        ];
                    }

                    isTruncated = data.IsTruncated; // Indique si d'autres fichiers doivent être récupérés
                    continuationToken = data.NextContinuationToken; // Récupère le token pour la prochaine page
                }

                // Trier les fichiers par date décroissante
                this.files.sort((a, b) => new Date(b.LastModifiedISO) - new Date(a.LastModifiedISO));
            } catch (e) {
                console.error(e);
                this.error = "Une erreur s'est produite lors de la récupération des fichiers.";
            } finally {
                this.isLoading = false;
            }
        },
        async downloadFile(key) {
            try {
                const {bucket} = this.getBucketAndPrefix();
                const s3 = new AWS.S3();
                const params = {
                    Bucket: bucket,
                    Key: key,
                };

                const data = await s3.getObject(params).promise();
                const blob = new Blob([data.Body], {type: "application/gzip"});

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = key.split("/").pop();
                link.click();
            } catch (e) {
                console.error(e);
                this.error = "Erreur lors du téléchargement du fichier.";
            }
        },
        callModal() {
            this.$bvModal.show('preview-modal');
        },
        async previewFile(key) {
            this.callModal();
            this.isPreviewLoading = true;
            this.chosenKey = key;

            try {
                const {bucket} = this.getBucketAndPrefix();
                const s3 = new AWS.S3();
                const params = {
                    Bucket: bucket,
                    Key: key,
                };

                // Récupérer le fichier depuis S3
                const data = await s3.getObject(params).promise();

                // Détecter le type de fichier
                const extension = this.getFileExtension(key);

                let decompressedData;
                if (extension === 'gz') {
                    // Décompresser GZ
                    decompressedData = pako.inflate(data.Body, {to: 'string'});
                } else if (extension === 'zst') {
                    // Décompresser ZST avec fzstd
                    const compressed = new Uint8Array(data.Body); // Convertir en Uint8Array
                    const decompressed = fzstd.decompress(compressed); // Décompresser
                    decompressedData = new TextDecoder('utf-8').decode(decompressed); // Convertir en texte
                } else {
                    console.error('Format de fichier non pris en charge.');
                }

                // Vérifier si le contenu est JSON
                let jsonData = JSON.parse(decompressedData);

                // Vérifier si c'est un tableau JSON
                if (Array.isArray(jsonData)) {
                    jsonData = jsonData.slice(0, 10);
                    this.previewContent = jsonData.map((item) => ({
                        Date: item.date,
                        ID: item.ID,
                        Pressure: item.P,
                        Temperature: item.T,
                        Humidity: item.U,
                        Latitude: item.lat,
                        Longitude: item.lon,
                        version: item.PR,
                        gsm: item.GSM,
                        data: item.trame,
                    }));
                } else {
                    this.previewContent = 'Format JSON inattendu.';
                }
            } catch (e) {
                console.error(e);
                this.error = 'Erreur lors de la prévisualisation du fichier.';
            } finally {
                this.isPreviewLoading = false;
                this.chosenKey = null;
            }
        },
        generateRange(start, end) {
            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
        },
        formatDate(date) {
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                timeZone: "UTC",
            };
            return new Date(date).toLocaleDateString("fr-FR", options);
        },
        clearPreview() {
            this.previewContent = null;
        }
    },
    computed: {
        paginatedFiles() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.files.slice(start, end);
        },
        dataByVersion() {
            if (this.sensorType.toLowerCase() === 'pollution' && this.sensorVersion === 'v2') {
                return {
                    offset: [1,32,63,94],
                    noise: [125,126,127,128],
                    gammes: 30,
                    dataRange: [
                        {key: "voie15", start: 2, end: 31},
                        {key: "voie60", start: 33, end: 62},
                        {key: "voie125", start: 64, end: 93},
                        {key: "voie160", start: 95, end: 124}
                    ]
                };
            }
            return {
                offset: [1,21,41,61],
                noise: [81,82,83,84],
                gammes: 19,
                dataRange: [
                    {key: "voie15", start: 2, end: 20},
                    {key: "voie60", start: 22, end: 40},
                    {key: "voie125", start: 42, end: 60},
                    {key: "voie160", start: 62, end: 80}
                ]
            };
        },
    },
    async mounted() {
        if (this.sensorVersion && this.sensorId) {
            await this.fetchFiles();
        }
    },
};
</script>

<style scoped>
/* Assure que les colonnes ont suffisamment d'espace */
.b-table th,
.b-table td {
    white-space: nowrap; /* Évite les retours à la ligne */
    text-align: center; /* Centrer le contenu */
}
</style>
