<template>
    <b-col sm="12" class="rounded shadow bg-white">
        <b-row class="bg-white justify-content-center align-content-center">
            <b-col cols="4"
                   class=" rounded add-cursor text-center pt-3"
                   @click="subPage = 'live'"
                   :class="subPage === 'live' ? `clr-${sensorType} bg-white` : 'clr-dark-grey bg-lighter-grey'"
            >
                <p class="">
                    LIVE MONITORING
                </p>
            </b-col>
            <b-col cols="4"
                   class="rounded add-cursor text-center pt-3"
                   @click="subPage = 'history'"
                   :class="subPage === 'history' ? `clr-${sensorType} bg-white` : 'clr-dark-grey bg-lighter-grey'">
                <p class="">
                    HISTORIQUE
                </p>
            </b-col>
            <b-col cols="4"
                   class="rounded text-center py-2 bg-lighter-grey">
                <p class="">
                </p>
            </b-col>
        </b-row>
        <b-row class="p-0 my-3" v-if="subPage === 'live'">
            <b-col cols="12" class="bg-white ">
                <p class="text-center h4 py-2 clr-dark-grey responsive-font">
                    Statuts Live
                    <b-icon icon="info-circle" :class="`clr-${sensorType}`" scale="0.8" v-b-modal.modal-help/>
                </p>
                <SensorMonitoring :sensor="actualSensor"/>
            </b-col>
        </b-row>
        <b-row class="p-0 my-3" v-else-if="subPage === 'history'">
            <b-col cols="12" class="bg-white ">
                <p class="text-center h4 py-2 clr-dark-grey responsive-font">
                    Logs d'erreurs
                    <b-icon icon="info-circle" :class="`clr-${sensorType}`" scale="0.8" v-b-modal.modal-help/>
                </p>
                <SensorMonitoringHistory :sensor="actualSensor" />
            </b-col>
        </b-row>
        <b-modal id="modal-help" scrollable centered size="huge" title="Explications sur les erreurs">
            <b-table-simple
                    responsive
                    hover
                    borderless>
                <b-thead>
                    <b-tr>
                        <b-th>Erreurs</b-th>
                        <b-th>Description</b-th>
                        <b-th>Notifications activé</b-th>
                        <b-th>Temps de résolution</b-th>
                        <b-th>Seuil d'alerte journalier</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr class="clr-light-grey" style="margin: 0">
                        <b-td class="align-middle">Nom de L'erreur (L'iD est commun à l'ensemble du systeme)</b-td>
                        <b-td class="align-middle">Description de l'erreur</b-td>
                        <b-td class="align-middle">Défini une erreur qui reçoit une alerte dès la premiere erreur</b-td>
                        <b-td class="align-middle">Temps après lequel on considère que l'erreur est résolue (en heure)</b-td>
                        <b-td class="align-middle">Compte du nombre d'alerte de même type qui déclenche un notification (peu importe les conditions)</b-td>
                    </b-tr>
                    <b-tr v-for="(error,i) in errorMatrix" :key="i"
                          class="add-cursor justify-content-center align-items-center clr-darker-grey">
                        <b-td class="align-middle">{{ error.ERROR }}</b-td>
                        <b-td class="align-middle">{{ error.DESCRIPTION }}</b-td>
                        <b-td class="align-middle">{{ error.NOTIFICATION ? 'Oui' : 'Non' }}</b-td>
                        <b-td class="align-middle">{{ error.RECOVERYTIME }}</b-td>
                        <b-td class="align-middle">{{ error.maxCriticalAlertPerDay }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-modal>
    </b-col>
</template>

<script>
import offsetCalculator from "@/services/sensor/offsetCalculator";
import operationRateCalculator from "@/services/sensor/operationRateCalculator";
import Vuex from "vuex";
import AWS from "@/services/aws/dynamodb";
import moment from "moment/moment";
import SensorMonitoring from "@/components/sensor/Detail/SensorMonitoring.vue";
import SensorMonitoringHistory from "@/components/sensor/Detail/SensorMonitoringHistory.vue";

export default {
    name: "SensorFollowUp",
    components: {SensorMonitoringHistory, SensorMonitoring},
    props:{
      actualSensor:Object,
      actualStatus:String,
    },
    data(){
        return {
            isViabilityLoading: true,
            offsetPeriod: null,
            isOffsetRecent: true,
            isOperationRateRecent: true,
            operationRatePeriod: null,
            dynamoValues:null,
            isBusy:null,
            isLoading:null,
            subPage: 'live'
        }
    },
    methods:{
        offsetColor(data) {
            return offsetCalculator.calculateOffsetLevel(data, this.actualSensor.sensorVersion);
        },
        operationRateColor(rate) {
            return operationRateCalculator.calculateOperationRateLevel(rate)
        },
        async getDataFromDynamoDb(limite = 100, order = false) {
            let dynamoDB = AWS.dynamoDB;

            let params = {
                TableName: "raw-data-BU",
                KeyConditionExpression: "id = :id",
                ExpressionAttributeValues: {
                    ":id": {'S': this.actualSensor.serialNumber}
                },
                Limit: limite,
                ScanIndexForward: order,
            };
            this.isBusy = true
            await dynamoDB.query(params, (err, data) => {
                if (err) {
                    console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
                } else {
                    if (data.Items.length > 0) {
                        this.dynamoValues = data.Items;
                    } else {
                        this.dynamoValues = {};
                    }
                    this.isBusy = false
                }
            });

        },
        isOnline(date, offset = 2) {
            let utcData = moment.utc(date);
            let twoHourAgo = moment.utc().subtract(offset, 'hours').toDate().toUTCString();
            return utcData.isAfter(twoHourAgo);
        }
    },
    computed:{
        ...Vuex.mapGetters('sensor', {
            actualOffsetHistory: "actualOffsetHistory",
            sensorsRate: "sensorsRate",
        }),
        ...Vuex.mapGetters('monitoring', {
            errorMatrix: "errorMatrix",
        }),
        sensorType() {
            if (!this.actualSensor) return 'pollen'
            return this.actualSensor.type.toLowerCase();
        }
    },
    mounted() {
        // Calculate offset data's viability
        let offsetViability = offsetCalculator.calculateOffsetViability(this.actualOffsetHistory);
        this.isOffsetRecent = offsetViability.isRecent;
        this.offsetPeriod = offsetViability.period;

        // Calculate operation rate data's viability
        let operationRateViability = operationRateCalculator.calculateOperationRateViability(this.sensorsRate)
        this.isOperationRateRecent = operationRateViability.isRecent;
        this.operationRatePeriod = operationRateViability.period;
    }
}
</script>

<style scoped>
.responsive-font{
    font-size: calc(15px + 0.390625vw)
}
</style>