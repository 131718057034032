<template>
    <b-col sm="12" class="p-0 m-0">
        <div class="rounded bg-white shadow p-3 h-100">
            <b-col cols="12">
                <b-row class="p-0 m-0 justify-content-between mb-2">
                    <b-col class="mt-md-0 mt-2" cols="12" md="4">
                        <div class="bg-white border-right">
                            <h5 class="subtitle-sensor-information mb-4 clr-dark-grey">
                                Informations Générales
                            </h5>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Numéro de série :</span> {{ actualSensor.serialNumber }}
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Type :</span> {{ type | capitalize }}
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Statut :</span> {{ status | capitalize }}
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Visibilité :</span>
                                {{ getVisibility(actualSensor.visibility) | capitalize }}
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Date d'intégration :</span>
                                {{ actualSensor.createdAt | convertDate() }}
                            </p>
                        </div>
                    </b-col>
                    <b-col class="mt-md-0 mt-2" cols="12" md="4">
                        <div class="bg-white border-right">
                            <h5 class="subtitle-sensor-information mb-4 clr-dark-grey">
                                Paramètres du capteur
                            </h5>
                            <p
                                class="subtitle-sensor-information mb-1 clr-darker-grey add-cursor"
                                @click="sendToExternalLink('https://m2mmanager.matooma.com/fr/home/parc-management/devices?simcardIccid='+actualSensor.sensorParameters.simId)"
                            >
                                <span class="font-weight-bold">Numéro Sim Matooma : </span>
                                <span v-if="actualSensor.sensorParameters.simId"
                                      class="add-cursor"
                                      :class="`clr-${sensorType}`"
                                      @click="sendToExternalLink('https://m2mmanager.matooma.com/fr/home/parc-management/devices?simcardIccid='+actualSensor.sensorParameters.simId)">{{
                                        actualSensor.sensorParameters.simId
                                    }}</span>
                                <span v-else>
                                    Carte Sim Non Paramétrée
                                </span>
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">WIFI : </span>
                                <span :class="actualSensor.sensorParameters.wifiEnable ? 'clr-green' : 'clr-red'">{{
                                        actualSensor.sensorParameters.wifiEnable ? 'Activé' : 'Désactivé'
                                    }}</span>
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Puissance Laser :</span>
                                {{ actualSensor.sensorParameters.laserPower | capitalize }}mW
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Position GPS : </span>
                                <span
                                    class="add-cursor"
                                    :class="`clr-${sensorType}`"
                                    v-if="actualSensor.sensorAddress && actualSensor.sensorAddress.lat !==0 && actualSensor.sensorAddress.lon !== 0"
                                    @click="sendToExternalLink(
                                    'https://www.google.com/maps/search/?api=1&query='+
                                    actualSensor.sensorAddress.lat+
                                    ','
                                    +actualSensor.sensorAddress.lon)"
                                >
                                    [{{ actualSensor.sensorAddress.lat }},{{ actualSensor.sensorAddress.lon }}]
                                </span>
                                <span
                                    class="add-cursor"
                                    :class="`clr-${sensorType}`"
                                    v-else-if="actualSensor.pollutionStatus !== undefined && actualSensor.pollutionStatus.lat !==0 && actualSensor.pollutionStatus.lon !== 0"
                                    @click="sendToExternalLink(
                                    'https://www.google.com/maps/search/?api=1&query='+
                                    actualSensor.pollutionStatus.lat+
                                    ','
                                    +actualSensor.pollutionStatus.lon)"
                                >
                                    [{{ actualSensor.pollutionStatus.lat }},{{ actualSensor.pollutionStatus.lon }}]
                                </span>
                                <span v-else>w
                                    GPS non paramétré
                                </span>
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Statut GPS :</span>
                                <span>
                                    {{
                                        actualSensor.sensorParameters.fixedGps ? 'Fixe' : 'Dynamique'
                                    }}
                                </span>
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Version :</span>
                                <span>
                                    {{ actualSensor.sensorVersion }}
                                </span>
                            </p>
                        </div>
                    </b-col>
                    <b-col class="mt-md-0 mt-2" cols="12" md="4" v-if="actualSensor.sensorAddress">
                        <div class="bg-white">
                            <h5 class="subtitle-sensor-information mb-4 clr-dark-grey">
                                Informations Spatiales
                            </h5>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Ville :</span> {{ actualSensor.sensorAddress.locality }}
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Département :</span>
                                {{ actualSensor.sensorAddress.administrativeAreaLvl2 | capitalize }}
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Région :</span>
                                {{ actualSensor.sensorAddress.administrativeAreaLvl1 | capitalize }}
                            </p>
                            <p class="subtitle-sensor-information mb-1 clr-darker-grey">
                                <span class="font-weight-bold">Pays (ShortCode) :</span> {{
                                    actualSensor.sensorAddress.countryLongName | capitalize
                                }},{{ actualSensor.sensorAddress.countryShortName | capitalize }}
                            </p>
                        </div>
                    </b-col>
                </b-row>
                <iframe
                    width="100%"
                    height="297"
                    style="border:0"
                    class="bg-transparent shadow mt-3"
                    loading="lazy"
                    allowfullscreen
                    referrerpolicy="no-referrer-when-downgrade"
                    :src="`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${actualSensor.sensorAddress.lat},${actualSensor.sensorAddress.lon}&zoom=15`"
                >
                </iframe>
            </b-col>
        </div>
    </b-col>


    <!--    </b-col>-->
</template>

<script>
import Vuex from "vuex";

export default {
    name: "SensorInformations",
    data() {
        return {
            visibilityOptions: [
                {value: 'public', text: 'Publique'},
                {value: 'private', text: 'Privé'},
                {value: 'hidden', text: 'Caché'},
            ],
            apiKey: 'AIzaSyAPqh1NQJL9t_XBAHhw_amgAaJqmp2yY7U'
        }
    },
    props: {
        actualSensor: Object,
        actualStatus: String,
        type: String
    },
    methods: {
        copyContent() {
            let element = this.$refs.contentToCopy.textContent;
            this.copyText(element.trim());
        },
        getVisibility(value) {
            return this.visibilityOptions.find(item => item.value === value).text
        },
        sendToExternalLink(url) {
            let win = window.open(url, '_blank');
            win.focus();
        },

        setEnvironmentalData(reports, type) {
            if (reports.length > 0) {
                let report = reports.find(report => report.reportType.name === type);
                if (report) {
                    return report.value
                } else {
                    return 'NaN'
                }
            }
        },
        ptuStatus(reports) {
            if (reports.length > 0) {
                let reportP = reports.find(report => report.reportType.name === 'pressure');
                let reportT = reports.find(report => report.reportType.name === 'temperature');
                let reportU = reports.find(report => report.reportType.name === 'humidity');

                if (reportP && reportT && reportU) {
                    return true
                }
            }

            return false
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            getSensorAccess: 'getSensorAccess'
        }),
        ...Vuex.mapGetters('sensorList', {
            getStepNameByValue: 'getStepNameByValue',
        }),
        status() {
            let parameters = this.actualSensor.sensorParameters;
            return parameters.status + ' - ' + this.getStepNameByValue(parameters.status, parameters.statusStep)
        },
        sensorType() {
            if (!this.actualSensor) return 'pollen'
            return this.actualSensor.type.toLowerCase();
        }
    }
}
</script>

<style scoped>

</style>